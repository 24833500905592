import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Spinner } from "@enpowered/ui";
import {
  useConfigureNotificationRecipient,
  useDeleteNotificationRecipient,
  useEnumerateNotificationRecipients,
  useEnumerateSites
} from "@/_hooks";
import { NotificationRecipientItem } from "./NotificationRecipientItem";
import { NotificationRecipientFormModal } from "./NotificationRecipientForm";

/**
 *
 * @param {object} props
 * @param {string} props.orgProfileId
 * @returns {JSX.Element}
 */
export const NotificationRecipientsPanel = ({ orgProfileId }) => {
  const [selectedRecipient, setSelectedRecipient] = useState(
    /** @type {import("@/_services").NotificationRecipient}*/ null
  );
  const [showForm, setShowForm] = useState(false);

  const {
    data: { items: recipients } = { items: [] },
    isLoading: isEnumerateNotificationRecipientsLoading
  } = useEnumerateNotificationRecipients({
    itemsPerPage: 60,
    organizationId: orgProfileId
  });

  const {
    mutate: configureNotificationRecipient,
    isLoading: isConfigureNotificationRecipient,
    error: configureNotificationRecipientError
  } = useConfigureNotificationRecipient(() => {
    setShowForm(false);
    setSelectedRecipient(null);
  });

  const { mutate: deleteNotificationRecipient } =
    useDeleteNotificationRecipient(() => {});

  const { data: sites, isLoading: isEnumerateSitesLoading } = useEnumerateSites(
    { owner: orgProfileId }
  );

  return (
    <div className="bg-white p-3 mb-4 w-full border-solid border-2 rounded-md border-en-gray-100">
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-bold text-lg">Notification Recipients</h2>
        <Button
          size="narrow"
          theme="dark"
          className="py-2"
          onClick={() => setShowForm(true)}
        >
          Add Recipient
        </Button>
      </div>
      {isEnumerateNotificationRecipientsLoading ? (
        <Spinner />
      ) : (
        <div>
          {recipients.map(recipient => (
            <NotificationRecipientItem
              key={recipient.recipientId}
              recipient={recipient}
              onEdit={r => {
                setSelectedRecipient(r);
                setShowForm(true);
              }}
              onDelete={({ name, recipientId }) => {
                if (
                  confirm(
                    `Are you sure you want to delete recipient ${
                      name ?? recipientId
                    }?`
                  )
                ) {
                  deleteNotificationRecipient({ recipientId });
                }
              }}
            />
          ))}
        </div>
      )}

      {showForm && (
        <NotificationRecipientFormModal
          isOpen={showForm}
          recipient={selectedRecipient}
          organizationId={orgProfileId}
          onCancel={() => {
            setShowForm(false);
            setSelectedRecipient(null);
          }}
          onSubmit={configureNotificationRecipient}
          isLoading={
            isConfigureNotificationRecipient || isEnumerateSitesLoading
          }
          sites={sites}
          error={configureNotificationRecipientError}
        />
      )}
    </div>
  );
};

NotificationRecipientsPanel.propTypes = {
  orgProfileId: PropTypes.string
};
