import { DateTime } from "luxon";

/**
 *
 * @param {string} start
 * @param {string} end
 * @param {string} timezone
 * @returns {string}
 */
export const getTimeRangeFromIso = (start, end, timezone) => {
  const startTime = DateTime.fromISO(start, { zone: timezone });
  const endTime = DateTime.fromISO(end, { zone: timezone });

  return `${startTime.toFormat(
    `h ${startTime.toFormat("a") === endTime.toFormat("a") ? "" : "a"}`
  )} - ${endTime.toFormat("h a")}`;
};

/**
 *
 * @param {import("luxon").DateTime} date
 * @param {string} range
 * @returns {[string, string]}
 */
export const getIsoFromDateAndTimeRange = (date, range) => {
  const [[startHour, startMeridiem], [endHour, endMeridiem]] = range
    .split(" - ")
    .map(time => time.split(" "));

  const startTime = date
    .plus({
      hours: get24HFormat(+startHour, startMeridiem || endMeridiem)
    })
    .toUTC()
    .toISO();
  const endTime = date
    .plus({ hours: get24HFormat(+endHour, endMeridiem) })
    .toUTC()
    .toISO();

  if (startTime > endTime) return [null, null];

  return [startTime, endTime];
};

const get24HFormat = (time, meridian) =>
  // 12 AM => 0 hr
  // 12 PM => 12 hrs
  // 2 AM => 2 hrs
  // 2 PM => 14 hrs
  time === 12
    ? meridian === "AM"
      ? 0
      : 12
    : meridian === "AM"
      ? time
      : time + 12;
