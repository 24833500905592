import { authedFetch } from "@/_utils/authedFetch";

/**
 *
 * @param {string} method
 * @param {object} data
 * @param {any} fetch
 * @returns
 */
export const callJsonRPC = (apiRoot, method, data, fetch = authedFetch) =>
  fetch(apiRoot, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      jsonrcp: "2.0",
      method,
      id: method,
      params: data
    })
  })
    .then(res => res.json())
    .then(({ result }) => result);
