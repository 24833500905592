import { cn } from "@/_utils/cn";
import { ConnectionStatus } from "@/usage-data/components/ConnectionStatus";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.organizationName
 * @param {string} props.status
 * @param {string} props.lastPull
 * @param {boolean} props.selected
 * @param {string} props.to
 * @returns
 */
export const ConnectionItem = ({
  label,
  organizationName,
  status,
  lastPull,
  selected,
  to
}) => {
  return (
    <Link
      to={to}
      className={cn(
        "shadow-md border-2 border-solid rounded-md p-2 block w-full",
        {
          "bg-en-yellow-300 border-en-yellow-700": selected,
          "bg-white border-en-gray-200 hover:bg-yellow-400": !selected
        }
      )}
    >
      <div className="mb-2">
        {status ? <ConnectionStatus status={status} /> : <span>No Status</span>}
      </div>
      <div className="truncate font-semibold">{label || "No Label"}</div>
      <div className="truncate">{organizationName || "No Oranization"}</div>
      {lastPull
        ? `Last data pull: ${DateTime.fromISO(lastPull).toRelative({
            style: "long"
          })}`
        : "No data pulls"}
    </Link>
  );
};
