import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

/**
 * @param {object} props
 * @param {number} props.rank
 * @param {string} props.timestamp
 * @param {number} props.length
 * @param {boolean} props.isPriorityHour
 * @param {boolean} [props.disabled]
 * @param {(cpHour: import("@/_services").CPHour) => any} [props.onChange]
 * @returns {JSX.Element}
 */
export const CPHour = ({
  rank,
  timestamp,
  length,
  isPriorityHour,
  disabled,
  onChange
}) => {
  const startTime = DateTime.fromISO(timestamp);
  const endTime = DateTime.fromISO(timestamp).plus({ hour: 1 });

  const timeRange = !timestamp
    ? "--"
    : startTime.toFormat("a") === endTime.toFormat("a")
      ? `${startTime?.toFormat("h")}-${endTime?.toFormat("ha")}`
      : `${startTime?.toFormat("ha")}-${endTime?.toFormat("ha")}`;

  const wrapperClass = classNames(
    "peak-hour-item rounded-md border flex items-center overflow-hidden",
    {
      "border-en-gray-100 text-en-gray-100": disabled,
      "border-en-gray-600": !disabled
    }
  );

  const timeRangeClass = classNames("block text-sm p-2", {
    "text-en-gray-400": !disabled,
    "text-en-gray-100": disabled
  });

  const selectionClass = classNames("h-full p-2 flex items-center", {
    "bg-white text-en-gray-100": disabled,
    "bg-white text-en-gray-200": !isPriorityHour && !disabled,
    "bg-en-yellow-500 text-white": isPriorityHour
  });

  const rankClass = classNames("text-white h-full text-sm", {
    "bg-black": !disabled,
    "bg-en-gray-100": disabled
  });

  /**
   *
   * @param {Partial<import("@/_services").CPHour>} values
   */
  const onHandleChange = values => {
    onChange &&
      onChange({
        rank,
        isPriorityHour,
        timestamp,
        ...values
      });
  };

  return (
    <div className={wrapperClass} style={{ height: "2.37rem" }}>
      <span className={timeRangeClass}>{timeRange}</span>
      <select
        className={rankClass}
        value={rank}
        onChange={e => onHandleChange({ rank: +e.target.value || 0 })}
        disabled={disabled}
      >
        <option value={0} disabled>
          -
        </option>
        {Array(length)
          .fill(undefined)
          .map((_, index) => (
            <option key={`${timeRange}-${index}`} value={index + 1}>
              {index + 1}
            </option>
          ))}
      </select>
      <div
        className={selectionClass}
        onClick={() =>
          !disabled && onHandleChange({ isPriorityHour: !isPriorityHour })
        }
      >
        <FontAwesomeIcon icon={faCheck} />
      </div>
    </div>
  );
};

CPHour.proptypes = {
  timestamp: PropTypes.string,
  rank: PropTypes.number,
  length: PropTypes.number,
  isPriorityHour: PropTypes.bool,
  onSelect: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool
};
