import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../user";

const ENPOWERED_ROLES = "enpowered_roles";
const ADMIN = "admin";
const SUPER_ADMIN = "superadmin";

export const RoleContext = React.createContext({
  roles: [],
  setRoles: () => {},
  // eslint-disable-next-line
  hasRole: role => false,
  isAdmin: false,
  isSuperAdmin: false,
  clear: () => {}
});

export const RoleContextProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [roles, setRoles] = useState(
    JSON.parse(localStorage.getItem(ENPOWERED_ROLES) || "[]")
  );
  const clear = () => {
    localStorage.removeItem(ENPOWERED_ROLES);
    setRoles([]);
  };

  const hasRole = role =>
    roles
      ?.slice(0, 1) // only consider the first company role
      .some(r => r && role && r.toLowerCase() === role.toLowerCase());

  const value = {
    roles,
    setRoles,
    hasRole,
    clear
  };

  Object.defineProperty(value, "isAdmin", {
    get: function () {
      return hasRole(ADMIN);
    }
  });

  Object.defineProperty(value, "isSuperAdmin", {
    get: function () {
      return hasRole(SUPER_ADMIN);
    }
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    const roles = [user?.organization?.role].filter(Boolean);
    setRoles(roles);
  }, [user]);

  useEffect(() => {
    localStorage.setItem(ENPOWERED_ROLES, JSON.stringify(roles));
  }, [roles]);

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

RoleContextProvider.propTypes = {
  children: PropTypes.any
};
