import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AutocompleteInput, Button } from "@enpowered/ui";
import classNames from "classnames";
import { getDefaultDispatchWindow } from "./utils";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DateTime, Duration } from "luxon";
import { InputAdornment } from "@mui/material";

const schema = yup.object({
  energyProgramId: yup.string().required(),
  drZones: yup.array().min(1),
  status: yup
    .string()
    .oneOf(["MAYBE", "NO_CALL", "CALLED", "CANCELLED", ""])
    .required(),
  eventIntervals: yup
    .array(
      yup.object({
        timestamp: yup.string().required(),
        duration: yup.string().required()
      })
    )
    .min(1)
    .required()
});

/**
 *
 * @param {object} props
 * @param {import("react-query").UseMutateFunction<any, any, import("@/_services").ProgramEvent[], unknown>} props.assertProgramEvents
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {import("luxon").DateTime} props.date
 * @param {{value: string, label: string}[]} props.availableZones
 * @param {boolean} props.isLoading
 * @param {() => void} props.onClose
 * @returns {JSX.Element}
 */

export const AddDREventForm = ({
  assertProgramEvents,
  energyProgram,
  date,
  availableZones,
  isLoading,
  onClose
}) => {
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    formState: { isValid }
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      energyProgramId: energyProgram.programId,
      status: "MAYBE",
      drZones: [],
      eventIntervals: []
    }
  });

  /**
   *
   * @param {object} params
   * @param {{value: string, label: string}[]} params.drZones
   * @param {string} params.energyProgramId
   * @param {{timestamp: string; duration: string}[]} params.eventIntervals
   * @param {"MAYBE"} params.status
   */
  const onSubmit = async ({ drZones, ...values }) => {
    assertProgramEvents(
      drZones.map(({ value: drZone }) => ({
        ...values,
        drZone
      }))
    );

    onClose && onClose();
  };

  useEffect(() => {
    if (!date) return;

    const { dispatchWindowStart: timestamp, duration } =
      getDefaultDispatchWindow(date, energyProgram.timezone);

    setValue("eventIntervals", [{ timestamp, duration }]);
    trigger();
  }, [date, energyProgram.timezone, setValue, trigger]);

  const onSelecteDate = value => {
    if (!value) return;

    const { dispatchWindowStart: timestamp, duration } =
      getDefaultDispatchWindow(value, energyProgram.timezone);

    setValue("eventIntervals", [{ timestamp, duration }]);
    trigger();
  };

  return (
    <form className="add-cp-event-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="border rounded-md border-en-yellow-500 shadow-md ">
        {!date && (
          <div
            className="grid grid-cols-[1fr_auto_auto] items-center p-4 border-en-gray-100 border-b"
            style={{ gridTemplateColumns: "1fr auto auto" }}
          >
            <MobileDatePicker
              className="w-52 [&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-0 [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
              disablePast
              onAccept={onSelecteDate}
              timezone={energyProgram.timezone}
            />
            {isValid && (
              <Button
                type="submit"
                size="narrow"
                className="rounded-r-none text-sm"
                disabled={isLoading}
              >
                Save
              </Button>
            )}
            <Button
              onClick={() => onClose && onClose()}
              className={classNames("text-sm", {
                "rounded-l-none": getValues("drZones").length > 0
              })}
              type="button"
              size="narrow"
              theme="dark"
            >
              X
            </Button>
          </div>
        )}
        <div
          className="grid justify-start items-center p-4 gap-4"
          style={{ gridTemplateColumns: "auto auto auto 1fr" }}
        >
          <div className="w-52">
            <label>Zones</label>
            <Controller
              control={control}
              name="drZones"
              render={({ field }) => (
                <AutocompleteInput
                  id="add-dr-event-autocomplete"
                  // prefix={undefined}
                  multiple
                  onSelected={values => {
                    setValue("drZones", values);
                    trigger();
                  }}
                  autocompleteData={availableZones.filter(
                    zone =>
                      !getValues("drZones")
                        .map(z => z.value)
                        .includes(zone.value)
                  )}
                  // className="grid grid-flow-col"
                  placeholder=""
                  isLoading={false}
                  maxResults={5}
                  valid
                  value={field.value}
                  name={field.name}
                  onBlur={() => {}}
                />
              )}
            />
          </div>
          {getValues("eventIntervals").length > 0 && (
            <>
              <div className="flex flex-col w-32">
                <label>Start Time</label>
                <MobileTimePicker
                  className="[&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-0 [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                  onChange={() => {}}
                  onAccept={time => {
                    if (!time || !time.isValid) return;
                    const newTime = time
                      .set({
                        day: date.day,
                        month: date.month,
                        year: date.year
                      })
                      .startOf("hour");
                    setValue("eventIntervals", [
                      {
                        timestamp: newTime.toISO(),
                        duration: getValues("eventIntervals")[0]?.duration
                      }
                    ]);
                  }}
                  value={DateTime.fromISO(
                    getValues("eventIntervals")[0]?.timestamp
                  )}
                  ampm={true}
                  ampmInClock={true}
                  format={`h a`}
                  views={["hours"]}
                  maxTime={DateTime.fromISO(
                    getValues("eventIntervals")[0]?.timestamp
                  ).plus(
                    Duration.fromISO(getValues("eventIntervals")[0]?.duration)
                  )}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {DateTime.now()
                              .setZone(energyProgram.timezone)
                              .toFormat("ZZZZ")}
                          </InputAdornment>
                        )
                      }
                    }
                  }}
                  timezone={energyProgram.timezone}
                />
              </div>
              <div className="flex flex-col w-32">
                <label>End Time</label>
                <MobileTimePicker
                  className="[&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-0 [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                  onChange={() => {}}
                  onAccept={time => {
                    if (!time || !time.isValid) return;
                    const newTime = time
                      .set({
                        day: date.day,
                        month: date.month,
                        year: date.year
                      })
                      .startOf("hour");
                    const duration = DateTime.fromISO(
                      getValues("eventIntervals")[0]?.timestamp
                    )
                      .diff(newTime)
                      .toISO();
                    setValue("eventIntervals", [
                      {
                        timestamp: getValues("eventIntervals")[0]?.timestamp,
                        duration: duration
                      }
                    ]);
                  }}
                  value={DateTime.fromISO(
                    getValues("eventIntervals")[0]?.timestamp
                  ).plus(
                    Duration.fromISO(getValues("eventIntervals")[0]?.duration)
                  )}
                  ampm={true}
                  ampmInClock={true}
                  format={`h a`}
                  views={["hours"]}
                  minTime={DateTime.fromISO(
                    getValues("eventIntervals")[0]?.timestamp
                  )}
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {DateTime.now()
                              .setZone(energyProgram.timezone)
                              .toFormat("ZZZZ")}
                          </InputAdornment>
                        )
                      }
                    }
                  }}
                  timezone={energyProgram.timezone}
                />
              </div>
            </>
          )}

          {!!date && (
            <div className="flex justify-end items-end">
              {getValues("drZones").length > 0 && (
                <Button
                  type="submit"
                  size="narrow"
                  className="rounded-r-none text-sm"
                  disabled={isLoading}
                >
                  Save
                </Button>
              )}
              <Button
                onClick={() => onClose && onClose()}
                className={classNames("text-sm", {
                  "rounded-l-none": getValues("drZones").length > 0
                })}
                type="button"
                size="narrow"
                theme="dark"
              >
                X
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

AddDREventForm.propTypes = {
  assertProgramEvent: PropTypes.func,
  energyProgram: PropTypes.object,
  date: PropTypes.object,
  availableZones: PropTypes.array,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func
};
