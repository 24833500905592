"use client";

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { Controller, FormProvider, useFormContext } from "react-hook-form";

import { cn } from "@/_utils/cn";
import { Label } from "@/_components/ui/label";

const Form = FormProvider;

/**
 * @typedef { object} FormFieldContextValue
 * @property { import("react-hook-form").FieldPath<import("react-hook-form").FieldValues>} [name]
 */

/** @type {React.Context<FormFieldContextValue>} */
const FormFieldContext = React.createContext({});

const FormField = (
  /** @type {import("react-hook-form").ControllerProps<import("react-hook-form").FieldValues, import("react-hook-form").FieldPath<import("react-hook-form").FieldValues>>} */
  { ...props }
) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const formContext = useFormContext();

  const fieldState = formContext?.getFieldState(
    fieldContext.name,
    formContext?.formState
  );

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};

/**
 * @typedef {object} FormItemContextValue
 * @property {string} [id]
 */

/** @type {React.Context<FormItemContextValue>} */
const FormItemContext = React.createContext({});

const FormItem = React.forwardRef(
  (
    /** @type {React.HTMLAttributes<HTMLDivElement>} */
    { className, ...props },
    ref
  ) => {
    const id = React.useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn("space-y-2", className)} {...props} />
      </FormItemContext.Provider>
    );
  }
);
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef(
  (
    /** @type {React.ComponentPropsWithoutRef<typeof import("@radix-ui/react-label").Root>} */
    { className, ...props },
    ref
  ) => {
    const { error, formItemId } = useFormField();

    return (
      <Label
        ref={ref}
        className={cn(error && "text-destructive", className)}
        htmlFor={formItemId}
        {...props}
      />
    );
  }
);
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef(
  (
    /** @type {React.ComponentPropsWithoutRef<typeof Slot>} */
    { ...props },
    ref
  ) => {
    const { error, formItemId, formDescriptionId, formMessageId } =
      useFormField();

    return (
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={
          !error
            ? `${formDescriptionId}`
            : `${formDescriptionId} ${formMessageId}`
        }
        aria-invalid={!!error}
        {...props}
      />
    );
  }
);
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef(
  (
    /** @type {React.HTMLAttributes<HTMLParagraphElement>} */
    { className, ...props },
    ref
  ) => {
    const { formDescriptionId } = useFormField();

    return (
      <p
        ref={ref}
        id={formDescriptionId}
        className={cn("text-sm text-muted-foreground", className)}
        {...props}
      />
    );
  }
);
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef(
  (
    /** @type {React.HTMLAttributes<HTMLParagraphElement>} */
    { className, children, ...props },
    ref
  ) => {
    const { error, formMessageId } = useFormField();
    const body = error ? String(error?.message) : children;

    if (!body) {
      return null;
    }

    return (
      <p
        ref={ref}
        id={formMessageId}
        className={cn("text-sm font-medium text-destructive", className)}
        {...props}
      >
        {body}
      </p>
    );
  }
);
FormMessage.displayName = "FormMessage";

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField
};
