import { Button, Spinner } from "@enpowered/ui";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";

import { useActivateSite, useEnumerateSites } from "@/_hooks";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { SiteFormModal } from "@/sites/components/SiteForm";

/**
 *
 * @param {object} props
 * @param {import("@/_services").OrgProfile} props.account
 * @returns {JSX.Element}
 */

export const SitesListPanel = ({ account }) => {
  const [showAddSitesModal, setShowAddSitesModal] = useState(false);
  const { data, isLoading } = useEnumerateSites({
    owner: account.orgProfileId
  });

  const { mutate: activateSite, isLoading: isActivateSiteLoading } =
    useActivateSite(() => {
      setShowAddSitesModal(false);
    });

  return (
    <div className="bg-white p-3 mb-4 w-full border-solid border-2 rounded-md border-en-gray-100">
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-bold text-lg">Sites</h2>

        <Button
          size="narrow"
          theme="dark"
          className="py-2"
          onClick={() => setShowAddSitesModal(true)}
        >
          Add Sites
        </Button>
      </div>

      {isLoading && <Spinner />}

      {!!data?.length && (
        <SitesList
          isLoading={isLoading}
          sites={data}
          accountId={account.orgProfileId}
        />
      )}

      <SiteFormModal
        isOpen={showAddSitesModal}
        onSubmit={activateSite}
        onCancel={() => setShowAddSitesModal(false)}
        isLoading={isActivateSiteLoading}
        account={account}
      />
    </div>
  );
};

SitesListPanel.propTypes = {
  account: PropTypes.object.isRequired
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").Site[]} props.sites
 * @param {boolean} props.isLoading
 * @param {string} props.accountId
 * @returns {JSX.Element}
 */
export const SitesList = ({ sites, isLoading, accountId }) => {
  const classHeader = "bg-gray-200 font-bold p-2";
  const classRow = "p-2";

  return (
    <div>
      {isLoading && <Spinner />}

      {!isLoading && sites.length && (
        <div>
          <div
            className="grid items-center"
            style={{ gridTemplateColumns: "minmax(20px, auto) 1fr auto" }}
          >
            <span className={classHeader}>Name</span>
            <span className={classHeader}>Address</span>
            <span className={classNames(classHeader, "text-gray-200")}>
              Actions
            </span>

            {sites.map(site => (
              <Fragment key={site.siteId}>
                <span className={classRow}>{site.siteInfo.name}</span>
                <span className={classRow}>
                  {[
                    site.siteInfo.location?.streetAddress,
                    site.siteInfo.location?.unit,
                    site.siteInfo.location?.city,
                    site.siteInfo.location?.state,
                    site.siteInfo.location?.country
                  ]
                    .filter(item => !!item)
                    .join(" ")}
                </span>
                <Button
                  className="underline hover:shadow-none block whitespace-nowrap"
                  theme="transparent"
                  as={Link}
                  // @ts-ignore
                  to={`/organizations/${accountId}/sites/${site.siteId}`}
                  addBaseHref={true}
                >
                  View Details
                </Button>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SitesList.propTypes = {
  accountId: PropTypes.string.isRequired,
  sites: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool
};
