import React, { useEffect } from "react";
import { ConnectionItem } from "./ConnectionItem";
import { Input } from "@/_components/ui/input";
import { Spinner } from "@/_components/spinner";
import { useInView } from "react-intersection-observer";

/**
 *
 * @param {object} props
 * @param {string} props.selectedId
 * @param {import("@/_services").Connection[]} props.connections
 * @param {boolean} [props.isLoading]
 * @param {() => void} props.getMoreConnections
 * @param {(searchTerm:string) => void} props.onSearch
 * @returns {JSX.Element}
 */
export const ConnectionsList = ({
  isLoading,
  selectedId,
  connections,
  getMoreConnections,
  onSearch
}) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      getMoreConnections?.();
    }
  }, [getMoreConnections, inView]);

  return (
    <div className="grid grid-rows-[auto+1fr] gap-4 p-4 h-full">
      <div>
        <Input
          placeholder="Search..."
          onChange={e => {
            if (e.target.value.length > 2) {
              onSearch?.(e.target.value);
            }

            if (e.target.value.length === 0) {
              onSearch?.("");
            }
          }}
        />
      </div>
      <div className="relative">
        <div className="grid grid-cols-1 auto-rows-min gap-2 absolute top-0 left-0 w-full h-full overflow-auto">
          {connections.map(item => (
            <ConnectionItem
              key={item.connectionId}
              label={item.label}
              organizationName={item.organizationName}
              status={item.status}
              lastPull={item.lastDataPull}
              selected={item.connectionId === selectedId}
              to={`/usage-data/${item.connectionId}`}
            />
          ))}

          {isLoading && (
            <div className="flex justify-center items-center h-full">
              <Spinner />
            </div>
          )}

          <div ref={ref} className="flex p-4 justify-center items-center"></div>
        </div>
      </div>
    </div>
  );
};
