import { cn } from "@/_utils/cn";
import { LoaderCircle } from "lucide-react";
import React from "react";

/**
 *
 * @param {object} props
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
export const Spinner = ({ className }) => {
  return (
    <LoaderCircle
      className={cn("animate-spin h-8 w-8 text-gray-700", className)}
    />
  );
};
