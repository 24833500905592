import { callJsonRPC } from "@/_utils/call-json-rpc";

const prefix = "com.enpowered.energy-usage-data";
const apiRoot = `${process.env.REACT_APP_API_ROOT}/energy-usage-data/jsonrpc`;

const METHOD = {
  enumerateConnections: `${prefix}:enumerateConnections`,
  initiateConnection: `${prefix}:initiateConnection`,
  updateConnectionAttributes: `${prefix}:updateConnectionAttributes`,
  enumerateUtilities: `${prefix}:enumerateUtilities`,
  filterEntities: `${prefix}:filterEntities`,
  getUsageDataSummary: `${prefix}:getUsageDataSummary`,
  downloadData: `${prefix}:downloadData`
};

/**
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {string} [params.id]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchTerm]
 * @param {string} [params.label]
 * @param {string} [params.organizationId]
 * @param {string} [params.organizationName]
 * @returns {Promise<{items: Connection[], cursor?:string}>}
 */
export const enumerateConnections = params =>
  callJsonRPC(apiRoot, METHOD.enumerateConnections, params);

/**
 *
 * @param {Connection} params
 * @returns {Promise<any>}
 */
export const initiateConnection = params =>
  callJsonRPC(apiRoot, METHOD.initiateConnection, params);

/**
 *
 * @param {object} params
 * @param {string} params.connectionId
 * @param {string} params.utilityId
 * @param {string} params.organizationId
 * @param {string} params.organizationName
 * @param {string} params.dataPullSchedule
 * @returns {Promise<any>}
 */
export const updateConnectionAttributes = params =>
  callJsonRPC(apiRoot, METHOD.updateConnectionAttributes, params);

/**
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {string} [params.id]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchTerm]
 * @returns {Promise<{items: Utility[], cursor?:string}>}
 */
export const enumerateUtilities = params =>
  callJsonRPC(apiRoot, METHOD.enumerateUtilities, params);

/**
 * @param {object} params
 * @param {string} params.searchTerm
 * @param {string} params.filterName
 * @param {{[key: string]: string | string[]}} [params.filterObjects]
 */
export const filterEntities = params =>
  callJsonRPC(apiRoot, METHOD.filterEntities, params);

/**
 * @param {object} params
 * @param {{[key: string]: string | string[]}} [params.filterObjects]
 */
export const getUsageDataSummary = params =>
  callJsonRPC(apiRoot, METHOD.getUsageDataSummary, params);

/**
 * @param {object} params
 * @param {string} params.fileOptions
 * @param {{[key: string]: string | string[]}} [params.filterObjects]
 */
export const downloadData = params =>
  callJsonRPC(apiRoot, METHOD.downloadData, params);

/**
 * @typedef {object} Connection
 * @property {string} [connectionId]
 * @property {string} [utilityId]
 * @property {string} [status]
 * @property {string} [lastDataPull]
 * @property {string} [contactEmail]
 * @property {string} label
 * @property {string} organizationId
 * @property {string} organizationName
 * @property {EnergyDataScope} scope
 * @property {string} dataPullSchedule
 * @property {string} [utilitySelectionUrl]
 * @property {GreenButton} greenButton
 *
 * @typedef {object} EnergyDataScope
 * @property {number} historyLengthInYears
 * @property {number} ongoingInYears
 * @property {string[]} datasets
 *
 * @typedef {object} Utility
 * @property {string} id
 * @property {string} name
 *
 * @typedef {object} GreenButton
 * @property {string} authorizationRequestUrl
 */
