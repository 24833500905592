import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  useConfigureProgramNotification,
  useEnumerateMarketDatasets,
  useEnumerateProgramEvents,
  useQueryEnergyPrograms
} from "@/_hooks";
import classNames from "classnames";
import { NotificationChart } from "./components/NotificationChart";
import { DateTime, Duration } from "luxon";
import { NotificationTemplates } from "./components/NotificationTemplates";
import { Button, TextInput } from "@enpowered/ui";
import { NotificationSchedulerModal } from "./components/NotificationScheduler";
import { Layout } from "@/_components/Layout";

const TABS = {
  CHART: {
    label: "Chart",
    forCP: true,
    forDR: false
  },
  TEMPLATES: {
    label: "Templates",
    forCP: true,
    forDR: true
  }
};

/**
 *
 * @returns {JSX.Element}
 */
export const CreateNotificationsPage = () => {
  const [searchParams] = useSearchParams();
  const programEventIds = searchParams.get("programEvents");
  const [chartImage, setChartImage] = useState("");
  const [notification, setNotification] = useState(
    /** @type {import("@/_services").ProgramNotification}*/ {
      messageTemplates: {}
    }
  );
  const [showScheduler, setShowScheduler] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [label, setLabel] = useState("");
  const navigate = useNavigate();

  // @ts-ignore
  // eslint-disable-next-line no-undef
  const listFormat = new Intl.ListFormat("en");

  const {
    data: programEvents = [],
    isLoading: isEnumerateProgramEventsLoading
  } = useEnumerateProgramEvents({ ids: programEventIds.split(",") });

  const {
    data: { items: [energyProgram] } = { items: [] },
    isLoading: isQueryEenrgyProgramsLoading
  } = useQueryEnergyPrograms({
    programId: programEvents?.[0]?.energyProgramId
  });

  const {
    data: { items: [marketDataset] } = { items: [] },
    isLoading: isEnumerateMarketDatasetsLoading
  } = useEnumerateMarketDatasets({ id: energyProgram?.programId });

  const {
    mutate: scheduleProgramNotification,
    isLoading: isScheduleProgramNotificationLoading
  } = useConfigureProgramNotification(() => {
    setTimeout(() => {
      navigate("/events");
    }, 1000);
  });

  const isLoading =
    isEnumerateProgramEventsLoading ||
    isQueryEenrgyProgramsLoading ||
    isEnumerateMarketDatasetsLoading ||
    !programEvents.length;

  const isCP = energyProgram?.programType === "COINCIDENT_PEAK";
  const isDR = energyProgram?.programType === "DEMAND_RESPONSE";

  useEffect(() => {
    if (!energyProgram) return;
    setSelectedTab(isCP ? TABS.CHART.label : isDR ? TABS.TEMPLATES.label : "");
  }, [energyProgram, isCP, isDR]);

  /**
   *
   * @param {Partial<import("@/_services").ProgramNotification>} attrs
   * @param {boolean} submit Should submit notification?
   */
  const updateNotification = (attrs = {}, submit = false) => {
    const newNotification = {
      ...notification,
      notificationScope: {
        programEvents: programEventIds.split(",")
      },
      ...(chartImage
        ? {
            programBlobs: [
              {
                key: "chartImage",
                value: chartImage
              }
            ]
          }
        : {}),
      ...attrs,
      label
    };
    setNotification(newNotification);

    if (submit) {
      scheduleProgramNotification(newNotification);
    }
  };

  /**
   * @param {import("@/_services").ProgramNotification} notification
   * @returns {string[]}
   */
  const validateNotification = notification => {
    /** @type {string[]} */
    const messages = [];

    // At lease there is one template
    const templates = Object.keys(notification.messageTemplates).filter(
      key => !!notification.messageTemplates[key]
    );
    if (templates.length === 0) messages.push("There are not templates");

    // if any template has any empty field
    templates.forEach(key => {
      const template = notification.messageTemplates[key];
      if (!template.email.subject)
        messages.push(`Email subject is required in ${key}`);
      if (!template.email.body)
        messages.push(`Email body is required in ${key}`);
      if (!template.sms.body) messages.push(`SMS body is required in ${key}`);
    });

    if (isCP) {
      // Check the screenshot exist
      if (!notification.programBlobs?.length)
        messages.push(`Chart image is required`);
    }

    return messages;
  };

  /**
   * @param {import("@/_services").ProgramEvent} programEvent
   * @param {boolean} [usePriorityHours]
   * @returns {string}
   */
  const getTimeWindow = (programEvent, usePriorityHours = false) => {
    /** @type {DateTime} */ let start;
    /** @type {DateTime} */ let end;

    if (isCP) {
      const intervals = usePriorityHours
        ? /** @type {import("@/_services").CPHour[]}*/ programEvent.eventIntervals.filter(
            ({ isPriorityHour }) => isPriorityHour
          )
        : programEvent.eventIntervals;

      start = DateTime.fromISO(intervals[0].timestamp, {
        zone: energyProgram.timezone
      });

      end = DateTime.fromISO(intervals.slice(-1)[0].timestamp, {
        zone: energyProgram.timezone
      }).plus({ hour: 1 });
    }

    if (isDR) {
      start = DateTime.fromISO(programEvent.eventIntervals[0].timestamp, {
        zone: energyProgram.timezone
      });

      end = start.plus(
        Duration.fromISO(
          /** @type {import("@/_services").DRInterval}*/ programEvent
            .eventIntervals[0].duration
        )
      );
    }

    const startMeridiem = start.toFormat("a");
    const endMeridiem = end.toFormat("a");
    const sameMeridiem = startMeridiem === endMeridiem;

    if (sameMeridiem)
      return `${start.toFormat("h")} - ${end.toFormat("h")} ${startMeridiem}`;
    else
      return `${start.toFormat("h")} ${startMeridiem} - ${end.toFormat(
        "h"
      )} ${endMeridiem}`;
  };

  const onClickSchedule = () => {
    setValidationErrors([]);
    const messages = validateNotification(notification);
    if (messages.length) {
      setValidationErrors(messages);
      return;
    }

    setShowScheduler(true);
  };

  useEffect(() => {
    if (!chartImage) return;
    updateNotification({});
  }, [chartImage]);

  return (
    <Layout pageTitle="Notifications">
      {showScheduler && (
        <NotificationSchedulerModal
          isOpen={showScheduler}
          onClose={() => setShowScheduler(false)}
          energyProgram={energyProgram}
          onSubmit={schedule => updateNotification({ schedule }, true)}
          isLoading={isScheduleProgramNotificationLoading}
        />
      )}

      <div className="w-full h-full px-20 py-4 overflow-y-auto">
        {isLoading ? (
          <div className="spin-when-empty"></div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h1 className=" text-3xl">
                <span className="font-bold">
                  {programEvents[0].cpConfidenceLevel}
                </span>{" "}
                notification for{" "}
                <span className="font-bold uppercase">
                  {programEvents[0].energyProgramId}
                </span>
              </h1>
            </div>
            <div className="grid grid-rows-[auto_1fr_auto] h-[calc(100%_-_5rem)] mt-4 gap-4 ">
              {isCP && (
                <div
                  className={classNames("grid gap-4", {
                    "grid-cols-2": programEvents.length > 1,
                    "grid-cols-1": programEvents.length === 1
                  })}
                >
                  {programEvents.map(programEvent => (
                    <div
                      key={programEvent.programEventId}
                      className="bg-white shadow-md rounded grid grid-cols-4 p-4 items-center w-full"
                    >
                      <div>
                        <div>Date:</div>
                        <div className="font-bold">
                          {DateTime.fromISO(
                            programEvent.eventIntervals[0].timestamp,
                            {
                              zone: energyProgram.timezone
                            }
                          ).toFormat("cccc, LLLL dd")}
                        </div>
                      </div>

                      <div>
                        <div>Forecast:</div>
                        <div className="font-bold">
                          {`${programEvent.cpForecastedPeakDemand.toLocaleString()} MW`}
                        </div>
                      </div>

                      <div>
                        <div>Window:</div>
                        <div className="font-bold">
                          {getTimeWindow(programEvent)}
                        </div>
                      </div>

                      <div>
                        <div>Peak window:</div>
                        <div className="font-bold">
                          {getTimeWindow(programEvent, true)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {isDR && (
                <div className="bg-white shadow-md rounded grid grid-cols-3 p-4 items-center w-full">
                  <div>
                    <div>Date:</div>
                    <div className="font-bold">
                      {DateTime.fromISO(
                        programEvents[0].eventIntervals[0].timestamp,
                        {
                          zone: energyProgram.timezone
                        }
                      ).toFormat("cccc, LLLL dd")}
                    </div>
                  </div>

                  <div>
                    <div>Zones:</div>
                    <div className="font-bold">
                      {listFormat.format(
                        programEvents.map(
                          ({ drZone }) => drZone.split(" - ")[1]
                        )
                      )}
                    </div>
                  </div>

                  <div>
                    <div>Window:</div>
                    <div className="font-bold">
                      {getTimeWindow(programEvents[0])}
                    </div>
                  </div>
                </div>
              )}

              <div className=" bg-white grid grid-rows-[auto_1fr] items-start shadow-md rounded h-full">
                <div className="flex justify-start w-full border-b">
                  {Object.values(TABS)
                    .filter(({ forCP, forDR }) =>
                      isCP ? forCP : isDR ? forDR : false
                    )
                    .map(({ label }) => (
                      <div
                        key={label}
                        className={classNames(
                          "p-4 border-r cursor-pointer flex items-center",
                          {
                            "font-bold": label === selectedTab
                          }
                        )}
                        onClick={() => setSelectedTab(label)}
                      >
                        {label}
                      </div>
                    ))}

                  <div className="grow text-sm text-red-500 flex flex-col items-end justify-center p-4">
                    {validationErrors.map((message, index) => (
                      <div key={`error-${index}`}>{message}</div>
                    ))}
                  </div>
                </div>

                {selectedTab === TABS.CHART.label && (
                  <NotificationChart
                    programEvents={programEvents}
                    energyProgram={energyProgram}
                    marketDataset={marketDataset}
                    setChartImage={setChartImage}
                  />
                )}

                {selectedTab === TABS.TEMPLATES.label && (
                  <NotificationTemplates
                    energyProgram={energyProgram}
                    notification={notification}
                    updateNotification={updateNotification}
                  />
                )}
                <div className="p-4 border-t grid grid-cols-[1fr_auto] gap-4">
                  <div className="grid grid-cols-[auto_1fr] gap-4 items-center">
                    <label>Label:</label>
                    <TextInput
                      value={label}
                      onChange={e => setLabel(e.target.value)}
                    />
                  </div>
                  <Button
                    size="narrow"
                    theme="dark"
                    // @ts-ignore
                    onClick={() => onClickSchedule()}
                  >
                    Schedule Notification
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
