import { Layout } from "@/_components/Layout";
import { Button } from "@/_components/ui/button";
import { useInfiniteConnections } from "@/_hooks";
import { cn } from "@/_utils/cn";
import { ConnectionsList } from "@/usage-data/components/ConnectionsList";
import React, { useMemo, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

export const UsageDataPage = () => {
  const { id } = useParams();
  let location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  const {
    data: connectionsData,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteConnections({ searchTerm: searchTerm || undefined });

  const connections = useMemo(
    () => (connectionsData?.pages || []).flatMap(page => page.items),
    [connectionsData]
  );

  return (
    <Layout pageTitle="Usage Data">
      <div className="grid grid-cols-[300px_1fr] h-full">
        <div>
          <ConnectionsList
            connections={connections}
            isLoading={isLoading || isFetchingNextPage}
            selectedId={id || ""}
            getMoreConnections={hasNextPage ? fetchNextPage : undefined}
            onSearch={setSearchTerm}
          />
        </div>
        <div className="grid grid-rows-[auto_1fr] gap-4 py-4 pr-4 max-h-full">
          <div className="flex justify-end">
            <Button
              asChild
              variant="outline"
              className={cn(
                "ml-2 px-3 py-1 shadow-md border-2 border-solid border-gray-500 rounded-md font-bold text-lg bg-white hover:bg-yellow-400 flex gap-2 items-center",
                {
                  "bg-yellow-400":
                    location.pathname === "/usage-data/new-connection"
                }
              )}
            >
              <Link to="/usage-data/new-connection">
                <i className="fas fa-plus"></i>New Connection
              </Link>
            </Button>

            <Button
              asChild
              variant="outline"
              className={cn(
                "ml-2 px-3 py-1 shadow-md border-2 border-solid border-gray-500 rounded-md font-bold text-lg bg-white hover:bg-yellow-400 flex gap-2 items-center",
                {
                  "bg-yellow-400":
                    location.pathname === "/usage-data/filter-data"
                }
              )}
            >
              <Link to="/usage-data/filter-data">Usage Data</Link>
            </Button>
          </div>
          <div className="relative">
            <div className="absolute top-0 left-0 w-full h-full overflow-auto">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
