import {
  enumerateConnections,
  enumerateUtilities,
  initiateConnection,
  updateConnectionAttributes
} from "@/_services/energy-usage-data.service";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";

/**
 *
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {string} [params.id]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchTerm]
 * @param {string} [params.label]
 * @param {string} [params.organizationId]
 * @param {string} [params.organizationName]
 * @returns {import("react-query").UseQueryResult<{items: import("@/_services").Connection[], cursor?:string}, object>}
 */
export const useEnumerateConnections = params =>
  useQuery(["connections", params], () => enumerateConnections(params));

/**
 *
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {string} [params.id]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchTerm]
 * @param {string} [params.label]
 * @param {string} [params.organizationId]
 * @param {string} [params.organizationName]
 * @returns {import("react-query").UseInfiniteQueryResult<{items: import("@/_services").Connection[], cursor?:string}, object>}
 */
export const useInfiniteConnections = params =>
  useInfiniteQuery({
    queryKey: ["infinite-connections", params],
    queryFn: ({ pageParam }) => {
      return enumerateConnections(pageParam ? { cursor: pageParam } : params);
    },
    getNextPageParam: lastPage => {
      // @ts-ignore
      return lastPage?.cursor;
    }
  });

/**
 *
 * @param {(connection: import("@/_services").Connection) => void} success
 * @returns {import("react-query").UseMutationResult<object, object>}
 */
export const useInitiateConnection = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {import("../_services").Connection} connection
     **/
    connection => initiateConnection(connection),
    {
      /** @param {object} props */
      onSuccess: ({ connectionId }, connection) => {
        queryClient.invalidateQueries(["connections"]);

        success && success({ ...connection, connectionId });
      }
    }
  );
};

/**
 *
 * @param {(connection: any) => void} success
 * @returns {import("react-query").UseMutationResult<object, object>}
 */
export const useUpdateConnectionAttributes = (success = () => {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    /**
     * @param {object} params
     * @param {string} params.connectionId
     * @param {string} params.utilityId
     * @param {string} params.organizationId
     * @param {string} params.organizationName
     * @param {string} params.dataPullSchedule
     **/
    params => updateConnectionAttributes(params),
    {
      /** @param {object} props */
      onSuccess: ({ connectionId }, connection) => {
        queryClient.invalidateQueries(["connections"]);

        success && success({ ...connection, connectionId });
      }
    }
  );
};

/**
 *
 * @param {object} params
 * @param {string} [params.cursor]
 * @param {string} [params.id]
 * @param {number} [params.itemsPerPage]
 * @param {string} [params.searchTerm]
 * @returns {import("react-query").UseQueryResult<{items: import("@/_services").Utility[], cursor?:string}, object>}
 */
export const useEnumerateUtilities = params =>
  useQuery(["utilities", params], () => enumerateUtilities(params));
