import { IconButton } from "@/_components/IconButton";
import { useEnumerateRecipientProgramNotificationsV2 } from "@/_hooks";
import { NotificationModal } from "@/notifications/components/NotificationModal";
import { Spinner } from "@enpowered/ui";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import React, { useMemo, useState } from "react";

/**
 * @param {object} props
 * @param {string} props.organizationId
 * @param {string} props.programNotificationId
 * @param {Partial<import("@/delivery-reports/DeliveryReportsPage").DeliveryReportsFilter>} props.filters
 * @returns {JSX.Element}
 */
export const RecipientsTable = ({
  organizationId,
  programNotificationId,
  filters
}) => {
  const [notificationModal, setNotificationModal] = useState(
    /** @type {{title: string; content: string; destination: string; status: string; message: string;}} */ null
  );
  const { data: notifications = [], isLoading } =
    useEnumerateRecipientProgramNotificationsV2({
      organizationId,
      programNotificationId,
      name: filters?.recipients?.name || undefined,
      email: filters?.recipients?.email || undefined,
      phoneNumber: filters?.recipients?.phone || undefined,
      status: filters?.recipients?.status || undefined
    });

  const columns = useMemo(
    () => [
      {
        header: "Name",
        cell: ({ row }) => row.original.name || "--"
      },
      {
        header: "Email",
        cell: ({ row }) => row.original.email || "--",
        size: 350
      },
      {
        header: "Phone Number",
        cell: ({ row }) => row.original.phoneNumber || "--"
      },
      {
        header: "Email Status",
        cell: ({ row }) => {
          const email = (row.original.messages || []).find(
            ({ channel }) => channel === "email"
          );
          return (
            <div className="w-full flex items-center gap-2">
              <span className="text-sm">
                {email ? email.status : "NONE SENT"}
              </span>
              {!!email && (
                <IconButton
                  icon="fa-eye"
                  onClick={() =>
                    setNotificationModal({
                      destination: row.original.email,
                      title: email.subject,
                      content: email.content,
                      status: email.status,
                      message: email.emailStatusDescription
                    })
                  }
                />
              )}
            </div>
          );
        }
      },
      {
        header: "SMS Status",
        cell: ({ row }) => {
          const sms = (row.original.messages || []).find(
            ({ channel }) => channel === "sms"
          );
          return (
            <div className="w-full flex items-center gap-4">
              <span className="text-sm">{sms ? sms.status : "NONE SENT"}</span>
              {!!sms && (
                <IconButton
                  icon="fa-eye"
                  onClick={() =>
                    setNotificationModal({
                      title: "SMS Notification",
                      destination: row.original.phoneNumber,
                      content: sms.content,
                      status: sms.status,
                      message: null
                    })
                  }
                />
              )}
            </div>
          );
        }
      }
    ],
    []
  );

  const table = useReactTable({
    data: notifications || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    debugTable: false
  });

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <table className="flex flex-col pr-4 overflow-auto h-full min-h-full">
        <thead className="grid sticky top-0 z-10">
          {table.getHeaderGroups().map(headerGroup => (
            <tr
              key={headerGroup.id}
              className="flex w-full bg-en-gray-800 text-white"
            >
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    className="flex w-full p-2"
                    style={{
                      width: header.getSize()
                    }}
                  >
                    <div
                      {...{
                        className: "cursor-pointer select-none",
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: " 🔼",
                        desc: " 🔽"
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              className="flex items-center border-b hover:bg-en-yellow-300"
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <td
                    key={cell.id}
                    className="flex w-full p-2 shrink-0"
                    style={{
                      width: cell.column.getSize()
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      {notificationModal && (
        <NotificationModal
          notification={notificationModal}
          isOpen={!!notificationModal}
          onClose={() => setNotificationModal(null)}
        />
      )}
    </>
  );
};
