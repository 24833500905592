import {
  Button,
  Checkbox,
  Link,
  PlusCircle,
  Spinner,
  SplitButtonDropdown
} from "@enpowered/ui";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  getAvailableActions,
  getNewStatus
} from "@/_utils/energy-programs.utils";
import { DRActivationModal } from "@/events/components/DRActivationModal";
import { AddDREventForm } from "@/events/components/AddDrEventForm";
import { DREventItem } from "@/events/components/DREventItem";

const STATUSES = ["MAYBE", "NO_CALL", "CALLED", "CANCELLED"];
const ACTION = {
  NONE: 0,
  ADD_PROGRAM_EVENT: 1,
  ACTIVATE_PROGRAM_EVENT: 2,
  EDIT_PROGRAM_EVENT: 3
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").ProgramEvent[]} props.programEvents
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {import("react-query").UseMutateFunction<any, any, Partial<import("@/_services").ProgramEvent>[], unknown>} props.assertProgramEvents
 * @param {boolean} [props.readOnly]
 * @param {boolean} [props.isLoading]
 * @param {import("@/_services").JsonRpcError} [props.error]
 * @returns {JSX.Element}
 */
export const DREvents = ({
  programEvents,
  energyProgram,
  assertProgramEvents,
  readOnly,
  isLoading = false,
  error
}) => {
  const date = programEvents.length
    ? DateTime.fromISO(programEvents[0].eventIntervals[0].timestamp, {
        zone: energyProgram.timezone
      })
    : null;

  const [currentAction, setCurrentAction] = useState(
    !date ? ACTION.ADD_PROGRAM_EVENT : ACTION.NONE
  );
  const [programEventsToTransition, setProgramEventsToTransition] = useState(
    []
  );
  const [programEventsToEdit, setProgramEventsToEdit] = useState([]);
  const [selectedProgramEvents, setSelectedProgramEvents] = useState(
    /** @type {import("@/_services").ProgramEvent[]}*/ []
  );

  const [showSendNotificationButton, setShowSendNotificationButton] =
    useState(false);

  const [availableActions, setAvailableActions] = useState([]);

  const availableStatuses = STATUSES.filter(status =>
    programEvents.some(event => event.status === status)
  );

  const getAvailableZones = () => {
    if (!programEvents.length) return energyProgram.drZones;

    const existingZones = programEvents.map(event => event.drZone);
    return energyProgram.drZones.filter(
      zone => !existingZones.includes(zone.value)
    );
  };

  /** @param {import("@/_services").ProgramEvent} programEvent */
  const toggleSelectEvent = programEvent => {
    const ids = selectedProgramEvents.map(
      ({ programEventId }) => programEventId
    );

    if (ids.includes(programEvent.programEventId)) {
      setSelectedProgramEvents(events =>
        events.filter(
          ({ programEventId }) => programEventId !== programEvent.programEventId
        )
      );
    } else {
      setSelectedProgramEvents(events => [...events, programEvent]);
    }
  };

  const toggleSelectAll = () => {
    setSelectedProgramEvents(events =>
      events.length === programEvents.length ? [] : programEvents
    );
  };

  /**
   * @param {import("@/_services").ProgramEvent[]} programEvents
   * @param {string} action
   */
  const onTransitions = (programEvents, action) => {
    if (action === "Call") {
      setProgramEventsToTransition(programEvents);
      setCurrentAction(ACTION.ACTIVATE_PROGRAM_EVENT);
      return;
    }

    assertProgramEvents(
      programEvents.map(({ programEventId, status }) => ({
        programEventId,
        status: getNewStatus(status, action)
      }))
    );
  };

  useEffect(() => {
    const sameStatus = selectedProgramEvents.every(
      event => event.status === selectedProgramEvents[0]?.status
    );

    const sameTime =
      selectedProgramEvents[0]?.status !== "CALLED" ||
      selectedProgramEvents.every(
        event =>
          event.eventIntervals[0].timestamp ===
            selectedProgramEvents[0].eventIntervals[0].timestamp &&
          /** @type {import("@/_services").DRInterval}*/ event.eventIntervals[0]
            .duration ===
            /** @type {import("@/_services").DRInterval}*/ selectedProgramEvents[0]
              .eventIntervals[0].duration
      );

    setShowSendNotificationButton(
      selectedProgramEvents.length > 0 && sameStatus && sameTime
    );

    setAvailableActions(
      selectedProgramEvents.length > 0 && sameStatus
        ? getAvailableActions(selectedProgramEvents[0]?.status)
        : []
    );
  }, [selectedProgramEvents]);

  const groupedEvents = () =>
    availableStatuses.reduce((groups, status) => {
      const events = programEvents.filter(event => event.status === status);
      if (status !== "DR_ACTIVE") return [...groups, events];

      // if DR_ACTIVE group by time
      const groupsByTime = events.reduce((output, event) => {
        const interval =
          /** @type {import("@/_services").DRInterval} */ event
            .eventIntervals[0];
        const key = `${interval.timestamp}-${interval.duration}`;
        if (output[key]) output[key].push(event);
        else output[key] = [event];

        return output;
      }, {});

      return [...groups, ...Object.values(groupsByTime)];
    }, []);

  return (
    <div className="w-full h-full p-4 flex flex-col">
      {currentAction === ACTION.ACTIVATE_PROGRAM_EVENT && (
        <DRActivationModal
          isOpen
          programEvents={programEventsToTransition}
          assertProgramEvents={programEvents => {
            assertProgramEvents(programEvents);
            setCurrentAction(ACTION.NONE);
          }}
          energyProgram={energyProgram}
          onClose={() => setCurrentAction(ACTION.NONE)}
          isLoading={isLoading}
          error={error?.data.description}
          activate
        />
      )}
      {currentAction === ACTION.EDIT_PROGRAM_EVENT && (
        <DRActivationModal
          isOpen
          programEvents={programEventsToEdit}
          assertProgramEvents={programEvents => {
            assertProgramEvents(programEvents);
            setCurrentAction(ACTION.NONE);
          }}
          energyProgram={energyProgram}
          onClose={() => setCurrentAction(ACTION.NONE)}
          isLoading={isLoading}
          error={error?.data.description}
          title="Set Time Window"
        />
      )}
      <div className="flex items-center justify-between">
        <div className="flex gap-4 items-center justify-start">
          {date && (
            <span className="font-bold text-sm px-4 py-2 bg-en-gray-200 rounded text-center">
              {date?.toFormat("ccc, LLL d")}
            </span>
          )}

          <span>
            <span className="font-bold">
              {energyProgram.programAdministrator}
            </span>{" "}
            - {energyProgram.name}
          </span>
        </div>
        {isLoading && <Spinner />}
      </div>

      {!!date && (
        <>
          {!readOnly && (
            <div
              className="flex justify-between py-2 pl-2 items-center gap-4"
              style={{ marginLeft: "1px" }}
            >
              <div className="flex justify-start items-center gap-4">
                <div className="flex flex-shrink-0 justify-start items-center gap-2 pt-1">
                  <Checkbox
                    size="small"
                    checked={
                      programEvents.length === selectedProgramEvents.length
                    }
                    onChange={toggleSelectAll}
                    name="select_all_dr_event"
                  />{" "}
                  <span className="text-sm">Select All</span>
                </div>
                {availableActions.map(option => (
                  <SplitButtonDropdown
                    key={option}
                    className="font-bold text-sm block"
                    confirm
                    onConfirm={action =>
                      onTransitions(selectedProgramEvents, action)
                    }
                    getOptionLabel={opt => opt}
                    options={[option]}
                    onClick={() => {}}
                  />
                ))}
              </div>
              <Button
                className={classNames("border border-1 ", {
                  "text-en-gray-200": getAvailableZones().length === 0,
                  "border-en-gray-200": getAvailableZones().length === 0,
                  "border-en-gray-800": getAvailableZones().length > 0
                })}
                size="narrow"
                theme="none"
                type="button"
                onClick={() =>
                  getAvailableZones().length > 0 &&
                  setCurrentAction(ACTION.ADD_PROGRAM_EVENT)
                }
              >
                <span className="flex gap-2 items-center [&>svg]:w-5 [&>svg]:h-5">
                  <PlusCircle
                    colour={
                      getAvailableZones().length === 0 ? "#CCCCCC" : "#343333"
                    }
                  />
                  Add DR Event
                </span>
              </Button>
            </div>
          )}
        </>
      )}

      {currentAction === ACTION.ADD_PROGRAM_EVENT && (
        <AddDREventForm
          assertProgramEvents={assertProgramEvents}
          energyProgram={energyProgram}
          date={date}
          availableZones={getAvailableZones() || []}
          isLoading={isLoading}
          onClose={() => setCurrentAction(ACTION.NONE)}
        />
      )}

      <div className="flex-grow overflow-y-auto">
        {groupedEvents().map((group, index) => (
          <div
            key={`group-${index}`}
            className="py-4 border-b last:border-b-0 first:pt-0"
          >
            {group.map(event => (
              <DREventItem
                key={event.programEventId}
                programEvent={event}
                energyProgram={energyProgram}
                onTransition={onTransitions}
                toggleSelectEvent={toggleSelectEvent}
                checked={
                  !!selectedProgramEvents.find(
                    ({ programEventId }) =>
                      programEventId === event.programEventId
                  )
                }
                readOnly={readOnly}
                onEdit={programEvent => {
                  setProgramEventsToEdit([programEvent]);
                  setCurrentAction(ACTION.EDIT_PROGRAM_EVENT);
                }}
              />
            ))}
          </div>
        ))}
      </div>

      {showSendNotificationButton && (
        <div className="flex-shrink-0 flex-grow-0 flex justify-end mt-4">
          <Link
            href={`/notifications/create?programEvents=${selectedProgramEvents
              .map(({ programEventId }) => programEventId)
              .join(",")}`}
            className="ml-4 font-bold px-4 rounded focus:outline-none hover:shadow-md py-1 text-white bg-en-gray-700"
          >
            Send Notification
          </Link>
        </div>
      )}
    </div>
  );
};

DREvents.propTypes = {
  eventGroup: PropTypes.object,
  energyProgram: PropTypes.object,
  assertProgramEvents: PropTypes.func,
  readOnly: PropTypes.bool
};
