import { DateTime } from "luxon";

/**
 *
 * @param {number} value
 * @returns {import("chartjs-plugin-annotation").LineAnnotationOptions & { type: "line" }}
 */
export const lineAnnotation = (value, borderColor = "black") => ({
  type: "line",
  borderColor,
  borderWidth: 4,
  label: {
    drawTime: "afterDatasetsDraw",
    backgroundColor: "black",
    content: `Threshold = ${value.toLocaleString("en-US")}`,
    display: false
  },
  scaleID: "y",
  value: value,
  enter({ element }) {
    element.label.options.display = true;
    return true;
  },
  leave({ element }) {
    element.label.options.display = false;
    return true;
  }
});

const renderTooltipOnCanvas = (text, backgroundColor, color) => {
  const canvas = document.createElement("canvas");
  canvas.width = 400;
  canvas.height = 30;
  const ctx = canvas.getContext("2d");

  ctx.font = "bold 14px sans-serif";

  ctx.fillStyle = backgroundColor;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.strokeStyle = "white";
  ctx.lineWidth = 4;
  ctx.strokeRect(0, 0, canvas.width, canvas.height);

  const textMetrics = ctx.measureText(text);
  const fontHeight =
    textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

  ctx.fillStyle = color;
  ctx.fillText(text, (canvas.width - textMetrics.width) / 2, fontHeight + 6);

  return canvas;
};

/**
 *
 * @param {string} xMin
 * @param {string} xMax
 * @param {string} content
 * @param {{ backgroundColor: string, color: string }} style
 * @returns {import("chartjs-plugin-annotation").BoxAnnotationOptions & { type: "box" }}
 */
export const boxAnnotation = (xMin, xMax, content, style) => {
  const backgroundColor = style.backgroundColor;
  const color = style.color;
  const canvasContent = renderTooltipOnCanvas(content, backgroundColor, color);
  return {
    type: "box",
    backgroundColor: backgroundColor,
    borderWidth: 2,
    borderColor: "white",
    xMin: xMin,
    xMax: xMax,
    label: {
      display: false,
      color: "black",
      drawTime: "afterDatasetsDraw",
      content: canvasContent,
      xAdjust: -100
    },
    enter({ element }) {
      element.label.options.display = true;
      return true;
    },
    leave({ element }) {
      element.label.options.display = false;
      return true;
    }
  };
};

/**
 *
 * @param {object} props
 * @param {boolean} props.beginAtZero
 * @param {number} props.xMaxTicksLimit
 * @param {number} props.yMaxTicksLimit
 * @param {boolean} props.displayLegend
 * @param {any[]} props.annotations
 * @returns {import("chart.js").ChartOptions<any>}
 */
export const chartOptions = ({
  beginAtZero,
  xMaxTicksLimit,
  yMaxTicksLimit,
  displayLegend,
  annotations
}) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: "time",
      time: {
        displayFormats: {
          hour: "T"
        },
        tooltipFormat: "LLL d, H:mm"
      },
      grid: {
        display: false
      },
      ticks: {
        align: "start",
        color: "black",
        font: {
          size: 16
        },
        maxTicksLimit: xMaxTicksLimit,
        maxRotation: 0,
        major: {
          enabled: true
        },
        callback: function (value, index, ticks) {
          const tick = ticks[index];
          const tickDate = DateTime.fromMillis(tick.value);

          if (tickDate.hour === 0) {
            return tickDate.toFormat("LLL d");
          } else {
            return tickDate.toFormat("hh:mm");
          }
        }
      }
    },
    y: {
      beginAtZero: beginAtZero,
      title: {
        display: false
      },
      ticks: {
        color: "black",
        font: {
          size: 16
        },
        maxTicksLimit: yMaxTicksLimit,
        mirror: true
      }
    }
  },
  layout: {
    padding: 20
  },
  plugins: {
    legend: {
      display: displayLegend,
      position: "bottom",
      onClick: (_click, legendItem, legend) => {
        const index = legend.legendItems.indexOf(legendItem);
        if (legend.chart.isDatasetVisible(index)) {
          legend.chart.hide(index);
        } else {
          legend.chart.show(index);
        }
      },
      labels: {
        padding: 30,
        color: "black",
        font: {
          size: 16
        },
        boxHeight: 6,
        boxWidth: 18,
        usePointStyle: true,
        generateLabels: chart =>
          chart.data.datasets.map((dataset, index) => {
            const isCircle = dataset.label.includes("Realtime");
            return {
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              strokeStyle: dataset.backgroundColor,
              pointStyle: isCircle ? "circle" : "rect",
              hidden: !chart.isDatasetVisible(index)
            };
          })
      }
    },
    annotation: {
      common: {
        drawTime: "beforeDraw"
      },
      annotations: annotations
    }
  }
});

export const getPeakWindowMaxIndex = peakWindow => {
  let maxValue = peakWindow?.[0]?.value;
  let maxIndex = 0;
  peakWindow.forEach((entry, idx) => {
    if (entry.value > maxValue) {
      maxIndex = idx;
      maxValue = entry.value;
    }
  });
  return maxIndex;
};
