import {
  Button,
  FileUpload,
  H,
  HelperText,
  Modal,
  Spinner
} from "@enpowered/ui";
import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

// import "./EnergyProgramDetail.css";
import {
  useCustomModelData,
  useDefaultModelData,
  useGetThreshold,
  useIsModelRefreshing,
  useRefreshModel,
  useSetThreshold,
  useUploadCustomModel
} from "@/_hooks";
import { EditableField } from "@/_components/EditableField";
import { ProgramManagementPreviewChart } from "./ProgramManagementPreviewChart";

/**
 *
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {boolean} props.hasCustomModelData
 * @param {() => any} [props.onSaveSuccess]
 * @returns {JSX.Element}
 */
export const EnergyProgramDetail = ({
  energyProgram,
  hasCustomModelData,
  onSaveSuccess
}) => {
  const [customThreshold, setCustomThreshold] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [isWaitingToRefetch, setIsWaitingToRefetch] = useState(false);

  const { data: threshold = 0, isLoading: isThresholdLoading } =
    useGetThreshold(energyProgram.programId);

  const {
    mutate: updateThreshold,
    isLoading: isUpdateThresholdLoading,
    error: updateThresholdError
  } = useSetThreshold(response => console.log(response));

  const { data: defaultModelData = [], isLoading: isDefaultModelDataLoading } =
    useDefaultModelData(energyProgram.programId);

  const { data: customModelData = [], isLoading: isCustomModelDataLoading } =
    useCustomModelData(energyProgram.programId, {
      enabled: hasCustomModelData
    });

  const {
    mutate: saveCustomModel,
    isLoading: isUploadCustomModelLoading,
    error: saveCustomModelError
  } = useUploadCustomModel(() => {
    propagateUpdate();
  });

  const propagateUpdate = () => onSaveSuccess && onSaveSuccess();

  const {
    refetch: refetchIsModelRefreshing,
    data: isModelRefreshing,
    isLoading: _isModelRefreshingLoading,
    isFetching: isModelRefreshingFetching
  } = useIsModelRefreshing(energyProgram.programId, {
    refetchOnWindowFocus: true,
    refetchInterval: 30000
  });

  const {
    mutate: setRefreshModel,
    isLoading: isSetRefreshModelLoading,
    error: setRefreshError
  } = useRefreshModel(() => {
    setIsWaitingToRefetch(true);
    setTimeout(() => {
      refetchIsModelRefreshing();
      setIsWaitingToRefetch(true);
    }, 5000);
  });

  const isModelRefreshingLoading =
    isModelRefreshing ||
    isSetRefreshModelLoading ||
    isModelRefreshingFetching ||
    _isModelRefreshingLoading ||
    isWaitingToRefetch;

  useEffect(() => {
    if (!file) return;

    csvRreader(file)
      .then(data => {
        const parsed = data
          .map(([timestamp, value]) => ({
            timestamp,
            value: +(value || 0)
          }))
          .filter(({ timestamp }) => !!timestamp);
        const [isValid, errorMsg] = isValidData(parsed, energyProgram.timezone);
        if (isValid) {
          setData(parsed);
          setShowPreview(true);
        }

        setError(errorMsg);
      })
      .catch(error => setError(error));
  }, [file, energyProgram.timezone]);

  useEffect(() => {
    if (!customModelData.length) return;

    setData(
      customModelData.map(({ timestamp, value }) => ({
        timestamp,
        value: +value
      }))
    );
  }, [customModelData]);

  const isLoading =
    isThresholdLoading ||
    isDefaultModelDataLoading ||
    isUploadCustomModelLoading ||
    isCustomModelDataLoading ||
    isUpdateThresholdLoading;

  return (
    <div
      className="energy-program-detail p-4 grid h-full"
      style={{ gridTemplateRows: "auto 1fr" }}
    >
      {showPublishModal && (
        <Modal
          isOpen={showPublishModal}
          onClose={() => setShowPublishModal(false)}
          size="md"
          title="Publish Modal Data"
        >
          <div>
            <div>
              Are you sure you wish to publish the model data?{" "}
              <b>
                Model data will need to be managed until{" "}
                {data[data.length - 1]?.timestamp}
              </b>
            </div>
            <div className="flex justify-end gap-4 mt-4">
              <Button
                size="narrow"
                theme="none"
                type="button"
                onClick={() => setShowPublishModal(false)}
              >
                Cancel
              </Button>
              <Button
                size="narrow"
                theme="dark"
                type="button"
                onClick={() => {
                  setShowPublishModal(false);
                  saveCustomModel({ programId: energyProgram.programId, file });
                }}
              >
                Publish Model Data
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div>
        <b>{energyProgram.programAdministrator}</b> - {energyProgram.nickname}{" "}
        Program
      </div>
      <div
        className="border border-en-yellow-500 shadow-md rounded-md p-4 mt-2 grid overflow-auto h-full custom-scroller"
        style={{ gridTemplateRows: "auto auto auto 1fr auto" }}
      >
        <div className="flex justify-between items-center">
          <div
            className="grid gap-4"
            style={{ gridTemplateColumns: "150px auto" }}
          >
            <div className="flex flex-col gap-2">
              <label className="text-sm">Threshold</label>
              <EditableField
                postfix="MW"
                value={customThreshold || threshold?.toString() || "0"}
                placeholder=""
                useLocale={true}
                onEditComplete={value => {
                  setCustomThreshold(value);
                  updateThreshold({
                    energyProgramId: energyProgram.programId,
                    threshold: +value
                  });
                }}
                showEditandSubmitIcons={false}
                Label={({ value, useLocale, disabled, onClick, postfix }) => (
                  <span
                    onClick={() => !disabled && onClick && onClick()}
                    className="p-2 w-full border rounded-md text-md block border-en-gray-300"
                    style={{ height: 35.75 }}
                  >{`${
                    useLocale
                      ? // @ts-ignore
                        isNaN(value)
                        ? value.toLocaleString()
                        : parseInt(value).toLocaleString()
                      : value
                  } ${postfix || ""}`}</span>
                )}
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-sm ">Refresh Models</label>
              <Button
                className="text-bold border rounded-md py-2 border-en-gray-300"
                type="button"
                onClick={() =>
                  !isModelRefreshingLoading &&
                  setRefreshModel(energyProgram.programId)
                }
                theme="none"
                size="narrow"
                disabled={isModelRefreshingLoading}
              >
                <span className="flex justify-center items center gap-2">
                  {isModelRefreshingLoading ? (
                    <>
                      <Spinner size={18} />
                      {isSetRefreshModelLoading || _isModelRefreshingLoading ? (
                        <span>Loading</span>
                      ) : (
                        <span>Refreshing Model</span>
                      )}
                    </>
                  ) : (
                    <>
                      <span className="material-symbols-outlined">refresh</span>
                      <span>Refresh Models</span>
                    </>
                  )}
                </span>
              </Button>
            </div>
            <HelperText valid={false}>
              {
                // @ts-ignore
                updateThresholdError?.errors?.[0]?.detail
              }
              {
                // @ts-ignore
                saveCustomModelError?.errors?.[0]?.detail
              }
              {
                // @ts-ignore
                setRefreshError?.errors?.[0]?.detail
              }
            </HelperText>
          </div>
          {isLoading && <Spinner />}
        </div>

        <div className="flex justify-between mt-4 border-b py-4">
          <div>
            <b>Prediction Models</b>
            <p>
              Export model data, and import below to view and set custom model
              data.
            </p>
          </div>

          {!isLoading && (
            <CSVLink
              className="font-bold px-4 focus:outline-none font-gotham hover:shadow-md text-bold border rounded-md py-2 border-en-gray-300 shadow-md flex items-center"
              data={defaultModelData?.map(record => [
                record.timestamp,
                record.value
              ])}
              filename={`${energyProgram.programId}-default-model-data.csv`}
            >
              <span className="flex justify-center items-center gap-2">
                <i className="fas fa-download"></i>
                <span>Export model</span>
              </span>
            </CSVLink>
          )}
        </div>
        {hasCustomModelData || showPreview ? (
          <>
            <div className="flex justify-end py-4 [&_.form-button]:min-h-[40.5px] [&_.form-button]:py-2 [&_.form-button]:px-4">
              <div className="flex flex-col items-end">
                {hasCustomModelData && (
                  <>
                    <FileUpload
                      accept="text/csv,.csv"
                      onChange={files => {
                        if (!files.length) return Promise.resolve();
                        setFile(files[0]);
                        return Promise.resolve();
                      }}
                      noFilesDisplay
                      className="font-bold border border-en-gray-300"
                    >
                      <div className="flex gap-2 items-center">
                        <i className="fas fa-upload"></i>
                        <span className="whitespace-nowrap">Import model</span>
                      </div>
                    </FileUpload>
                    {!!error && (
                      <div className="mt-4">
                        <HelperText key={error} valid={false}>
                          {error}
                        </HelperText>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <ProgramManagementPreviewChart
              energyProgram={energyProgram}
              data={data}
              threshold={customThreshold ? +customThreshold : threshold || 0}
              height="400px"
            />
            <div className="flex items-end justify-end">
              {!!file && (
                <div className="flex justify-end gap-4">
                  <Button
                    size="narrow"
                    theme="none"
                    type="button"
                    onClick={() => {
                      setData([]);
                      setShowPreview(false);
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    size="narrow"
                    theme="dark"
                    type="button"
                    onClick={() => !!data && setShowPublishModal(true)}
                    disabled={!data}
                  >
                    Publish Model Data
                  </Button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="mt-8  flex flex-col gap-4">
            <FileUpload
              accept="text/csv,.csv"
              className={{
                "w-full p-4 h-32 flex flex-col items-center justify-center cursor-pointer rounded-lg border-dashed border-2 border-en-yellow-800 relative":
                  true,
                "form-button": false
              }}
              onChange={files => {
                if (!files.length) return Promise.resolve();
                setFile(files[0]);
                return Promise.resolve();
              }}
            >
              <div className="flex items-center">
                <H level={4} as="div" className="cursor-pointer text-center">
                  Drag Model Data here or
                </H>
                <span className="rounded p-2 m-2 bg-black text-white text-sm font-bold">
                  Browse Files
                </span>
              </div>
            </FileUpload>
            {!!error && (
              <div className="mt-4">
                <HelperText key={error} valid={false}>
                  {error}
                </HelperText>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

EnergyProgramDetail.props = {
  energyProgram: PropTypes.object,
  hasCustomModelData: PropTypes.bool,
  onSaveSuccess: PropTypes.func
};

const csvRreader = file => {
  return new Promise((resolve, reject) => {
    if (!file || !file.name.endsWith(".csv"))
      reject("This file extension is not permitted. Please select a CSV file");

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { quoteChar: '"' });
      resolve(csv?.data);
    };

    reader.readAsText(file);
  });
};

/**
 * @param {{timestamp: string, value: number}[]} data
 * @param {string} timezone
 * @returns {[isValid: boolean, errorMsg: string]}
 */
const isValidData = (data, timezone) => {
  if (data.length !== 25) return [false, "The file should have 25 records"];

  const startOfDay = DateTime.now().setZone(timezone).startOf("day");

  const isDateOK = data.every(
    (record, index) =>
      record.timestamp === startOfDay.plus({ hours: index }).toUTC().toISO()
  );

  if (!isDateOK)
    return [
      false,
      `Some dates are wrong. Dates should be for the current day, by hour from ${startOfDay
        .toUTC()
        .toISO()} to ${startOfDay.plus({ hours: 24 }).toUTC().toISO()}`
    ];

  return [true, ""];
};
