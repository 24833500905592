import { Button, Spinner, TextInput } from "@enpowered/ui";
import { InputAdornment } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import classNames from "classnames";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";

import {
  enumerateMarketDatasets,
  enumerateProgramEvents,
  queryEnergyPrograms
} from "@/_services";

import {
  useConfigureProgramNotification,
  useCancelProgramNotification
} from "@/_hooks";

import { ConfirmCancelModal } from "./ConfirmCancelModal";
import { NotificationChart } from "./NotificationChart";
import { NotificationRecipientsList } from "./NotificationRecipientsList";
import { NotificationTemplates } from "./NotificationTemplates";

const MODAL = {
  NONE: "none",
  CANCEL: "cancel-notification"
};

/**
 * @param {object} props
 * @param {import("@/_services").ProgramNotification} props.notification
 * @param {(notification: import("@/_services").ProgramNotification) => void} [props.onUpdate]
 * @returns {JSX.Element}
 */
export const NotificationDetail = ({ notification, onUpdate }) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [programEvents, setProgramEvents] = useState([]);
  const [energyProgram, setEnergyProgram] = useState(null);
  const [marketDataset, setMarketDataset] = useState(null);
  const [notificationState, setNotificationState] = useState(notification);
  const [chartImage, setChartImage] = useState(
    notificationState?.programBlobs?.find(({ key }) => key === "chartImage")
      ?.value || ""
  );
  const [isEditable, setIsEditable] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(MODAL.NONE);

  const {
    mutate: configureProgramNotificationMutation,
    isLoading: isConfigureProgramNotificationLoading
  } = useConfigureProgramNotification(data => {
    setIsEditable(false);
    onUpdate(data);
  });

  const {
    mutate: cancelProgramNotificationMutation,
    isLoading: isCancelProgramNotificationLoading
  } = useCancelProgramNotification(() => {
    setShowModal(MODAL.NONE);
    onUpdate({
      ...notification,
      status: "CANCELLED"
    });
  });

  const { energyProgramId, dateToShow, tabs, notificationStatus, isCancelled } =
    useMemo(() => {
      const {
        notificationScope: {
          programEvents: [programEvent]
        },
        schedule,
        status
      } = notificationState;

      const isCancelled = status === "CANCELLED";

      const energyProgramId =
        programEvent?.split("__").length === 3
          ? programEvent?.split("__").splice(-1)[0]
          : programEvent?.split("__").splice(-3)[0];

      const scheduled = DateTime.fromISO(schedule);

      const dateToShow = scheduled.toFormat("MM/dd/yyyy hh:mm a");

      const notificationStatus =
        scheduled.diffNow().as("seconds") > 0 ? "Scheduled" : "Sent";

      const isCP = energyProgramId.indexOf("dr") === -1;

      const tabs = Object.fromEntries(
        Object.entries({
          CHART: {
            label: "Chart",
            enabled: isCP
          },
          TEMPLATES: {
            label: "Templates",
            enabled: true
          },
          RECIPIENTS: {
            label: "Delivery",
            enabled: true
          }
        }).filter(([, { enabled }]) => enabled)
      );
      if (!selectedTab) setSelectedTab(Object.keys(tabs)[0]);

      return {
        energyProgramId,
        dateToShow,
        tabs,
        notificationStatus,
        isCancelled
      };
    }, [notificationState, selectedTab]);

  const canUpdate = useMemo(
    () =>
      !_.isEqual(notification, {
        ...notificationState,
        programBlobs: [{ key: "chartImage", value: chartImage }]
      }),
    [chartImage, notification, notificationState]
  );

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      queryEnergyPrograms({
        programId: energyProgramId
      }),
      enumerateMarketDatasets({ id: energyProgramId }),
      ...notification.notificationScope.programEvents.map(id =>
        enumerateProgramEvents({ id })
      )
    ]).then(([program, marketData, ...events]) => {
      setEnergyProgram(program.items[0]);
      setMarketDataset(marketData.items[0]);
      setProgramEvents(events.map(({ items }) => items[0]));
      setIsLoading(false);
    });
  }, [
    notificationStatus,
    notification,
    enumerateProgramEvents,
    energyProgramId,
    queryEnergyPrograms,
    enumerateMarketDatasets
  ]);

  const onSubmit = async () => {
    const data = {
      ...notificationState,
      programBlobs: [{ key: "chartImage", value: chartImage }]
    };
    delete data.status;
    delete data.programBlobUrls;
    delete data.scheduleId;

    const validationErrors = validateNotification(data);
    if (validationErrors.length) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors([]);
    }

    await configureProgramNotificationMutation(data);
  };

  /**
   * @param {import("@/_services").ProgramNotification} notification
   * @returns {string[]}
   */
  const validateNotification = notification => {
    /** @type {string[]} */
    const messages = [];

    // At lease there is one template
    const templates = Object.keys(notification.messageTemplates).filter(
      key => !!notification.messageTemplates[key]
    );
    if (templates.length === 0) messages.push("There are not templates");

    // if any template has any empty field
    templates.forEach(key => {
      const template = notification.messageTemplates[key];
      if (!template.email.subject)
        messages.push(`Email subject is required in ${key}`);
      if (!template.email.body)
        messages.push(`Email body is required in ${key}`);
      if (!template.sms.body) messages.push(`SMS body is required in ${key}`);
    });

    return messages;
  };

  const onCancelNotification = async () => {
    await cancelProgramNotificationMutation({
      notificationId: notification.programNotificationId
    });
  };

  const isSaving =
    isConfigureProgramNotificationLoading || isCancelProgramNotificationLoading;

  return (
    <div className="w-full h-full flex flex-col p-4 gap-4">
      {showModal === MODAL.CANCEL && (
        <ConfirmCancelModal
          isOpen={true}
          isLoading={isCancelProgramNotificationLoading}
          onClose={() => setShowModal(MODAL.NONE)}
          onConfirm={() => onCancelNotification()}
        />
      )}
      <div className="grid grid-cols-[1fr_auto] gap-4 items-center">
        {isEditable ? (
          <TextInput
            placeholder="Label"
            value={notificationState.label}
            onChange={e =>
              setNotificationState(previous => ({
                ...previous,
                label: e.target.value
              }))
            }
          />
        ) : (
          <h1 className="font-bold text-2xl">
            {notificationState.label || "No label"}
          </h1>
        )}

        {!isCancelled && notificationStatus === "Scheduled" && (
          <div className="flex gap-4 items-center">
            {isEditable ? (
              <>
                <Button
                  size="narrow"
                  theme="transparent"
                  onClick={() => {
                    setIsEditable(false);
                    setNotificationState(notification);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="narrow"
                  theme="dark"
                  onClick={onSubmit}
                  disabled={!canUpdate || isSaving}
                >
                  {isSaving ? <Spinner /> : "Update"}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="narrow"
                  theme="dark"
                  onClick={() => setIsEditable(true)}
                >
                  Edit
                </Button>
                <Button
                  size="narrow"
                  theme="none"
                  onClick={() => setShowModal(MODAL.CANCEL)}
                  className="bg-en-red text-white"
                >
                  Cancel Notification
                </Button>
              </>
            )}
          </div>
        )}
      </div>

      <div className="bg-white shadow-md rounded p-4 grid grid-cols-[1fr_auto] gap-4 items-center">
        <div>
          <div className="flex gap-4">
            <label className="font-bold">Notification ID:</label>
            <span>{notificationState.programNotificationId}</span>
          </div>
          <div className="flex gap-4">
            <label className="font-bold">Energy Program:</label>
            <p className="uppercase">{energyProgramId}</p>
          </div>
        </div>
        {isCancelled ? (
          <div className="text-en-red font-bold">CANCELLED</div>
        ) : (
          <div className="flex flex-col">
            <div className="text-right">{notificationStatus}</div>
            {isEditable ? (
              <div>
                <MobileDateTimePicker
                  className="[&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-0 [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                  ampm={true}
                  ampmInClock={true}
                  value={DateTime.fromISO(notificationState.schedule)}
                  onAccept={date =>
                    setNotificationState(previous => ({
                      ...previous,
                      schedule: date.toISO()
                    }))
                  }
                  onChange={() => {}}
                  timezone={energyProgram.timezone}
                  disablePast
                  slotProps={{
                    textField: {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {DateTime.now().toFormat("ZZZZ")}
                          </InputAdornment>
                        )
                      }
                    }
                  }}
                />
              </div>
            ) : (
              <span className="text-xl font-semibold">{dateToShow}</span>
            )}
          </div>
        )}
      </div>

      <div className="bg-white shadow-md rounded h-full w-full grow grid grid-rows-[auto_1fr]">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex justify-start w-full border-b">
              {Object.entries(tabs).map(([key, { label }]) => (
                <div
                  key={key}
                  className={classNames(
                    "p-4 border-r cursor-pointer flex items-center",
                    {
                      "font-bold": key === selectedTab
                    }
                  )}
                  onClick={() => setSelectedTab(key)}
                >
                  {label}
                </div>
              ))}

              <div className="grow text-sm text-red-500 flex flex-col items-end justify-center p-4">
                {errors.map((message, index) => (
                  <div key={`error-${index}`}>{message}</div>
                ))}
              </div>
            </div>
            {selectedTab === "CHART" &&
              !!energyProgram &&
              !!programEvents &&
              marketDataset && (
                <>
                  {isEditable ? (
                    <NotificationChart
                      programEvents={programEvents}
                      energyProgram={energyProgram}
                      marketDataset={marketDataset}
                      setChartImage={setChartImage}
                    />
                  ) : (
                    <div className="p-4">
                      <div className="font-semibold text-xl pb-4 text-center">
                        Image chart preview
                      </div>
                      <img src={chartImage} className="object-scale-down" />
                    </div>
                  )}
                </>
              )}

            {selectedTab === "TEMPLATES" && energyProgram && (
              <NotificationTemplates
                energyProgram={energyProgram}
                notification={notificationState}
                updateNotification={({ messageTemplates }) => {
                  setNotificationState(old => ({ ...old, messageTemplates }));
                }}
                isEditable={isEditable}
              />
            )}

            {selectedTab === "RECIPIENTS" && (
              <NotificationRecipientsList notification={notification} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
