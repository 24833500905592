import { cn } from "@/_utils/cn";
import React from "react";

/**
 *
 * @param {object} props
 * @param {string} props.status
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
export const ConnectionStatus = ({ status, className }) => {
  return (
    <span
      className={cn(
        "py-1 px-2 text-xs rounded-md",
        {
          "bg-green-200 text-green-700": status === "AUTHORIZED",
          "bg-yellow-200 text-yellow-700": status === "PENDING",
          "bg-red-200 text-red-700": status === "EXPIRED"
        },
        className
      )}
    >
      {status}
    </span>
  );
};
