import * as React from "react";
import { cva } from "class-variance-authority";

import { cn } from "@/_utils/cn";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2 py-1 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground"
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
);

/**
 *
 * @typedef {import("react").HTMLAttributes<HTMLDivElement> &
 *  import("class-variance-authority").VariantProps<typeof badgeVariants> &
 *  { asChild?: boolean }
 * } BadgeProps
 *
 * @param {BadgeProps} props
 * @returns {JSX.Element}
 */
function Badge({ className, variant, ...props }) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
