import { cn } from "@/_utils/cn";
import { Command as CommandPrimitive } from "cmdk";
import { Check } from "lucide-react";
import { useMemo, useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList
} from "@/_components//ui/command";
import { Input } from "@/_components/ui/input";
import {
  Popover,
  PopoverAnchor,
  PopoverContent
} from "@/_components//ui/popover";
import React from "react";
import { Skeleton } from "@/_components/ui/skeleton";

/**
 *
 * @param {object} props
 * @param {string} props.selectedValue
 * @param {(value: string) => void} props.onSelectedValueChange
 * @param {string} props.searchValue
 * @param {(value: string, withSelectedItem: boolean) => void} props.onSearchValueChange
 * @param {{value: string, label: string}[]} props.items
 * @param {boolean} [props.isLoading]
 * @param {string} [props.emptyMessage]
 * @param {string} [props.placeholder]
 * @param {boolean} [props.resetOnBlur]
 * @param {(value: string) => void} [props.onBlur]
 * @param {() => void} [props.onFocus]
 * @param {string} [props.className]
 * @returns {JSX.Element}
 */
export const Autocomplete = ({
  selectedValue,
  onSelectedValueChange,
  searchValue,
  onSearchValueChange,
  items,
  isLoading,
  emptyMessage = "No items.",
  placeholder = "Search...",
  resetOnBlur = true,
  onBlur,
  onFocus,
  className
}) => {
  const [open, setOpen] = useState(false);

  const labels = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          acc[item.value] = item.label;
          return acc;
        },
        /** @type {Record<string, string>} */ {}
      ),
    [items]
  );

  const reset = () => {
    onSelectedValueChange("");
    onValueChange("");
  };

  /**
   *
   * @param {React.FocusEvent<HTMLInputElement>} e
   */
  const onInputBlur = e => {
    if (
      !e.relatedTarget?.hasAttribute("cmdk-list") &&
      labels[selectedValue] !== searchValue
    ) {
      resetOnBlur && reset();
    }

    setOpen(false);
    onBlur?.(searchValue);
  };

  /**
   *
   * @param {string} inputValue
   */
  const onSelectItem = inputValue => {
    if (inputValue === selectedValue) {
      reset();
    } else {
      onSelectedValueChange(inputValue);
      onSearchValueChange(labels[inputValue] ?? "", true);
    }
    setOpen(false);
  };

  const onValueChange = e => {
    onSearchValueChange(e, false);
  };

  return (
    <div className="flex items-center">
      <Popover open={open}>
        <Command shouldFilter={false}>
          <PopoverAnchor asChild>
            <Input
              placeholder={placeholder}
              value={searchValue}
              onChange={e => onValueChange(e.target.value)}
              onKeyDown={e => setOpen(e.key !== "Escape")}
              // onMouseDown={() => setOpen(open => !!searchValue || !open)}
              onFocus={() => {
                setOpen(true);
                onFocus?.();
              }}
              onBlur={onInputBlur}
              className={className}
            />
          </PopoverAnchor>
          {!open && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            asChild
            onOpenAutoFocus={e => e.preventDefault()}
            onInteractOutside={e => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className="w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              {isLoading && (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-6 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              )}
              {items.length > 0 && !isLoading ? (
                <CommandGroup>
                  {items.map(option => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onMouseDown={e => e.preventDefault()}
                      onSelect={onSelectItem}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedValue === option.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              ) : null}
              {!isLoading ? (
                <CommandEmpty>{emptyMessage ?? "No items."}</CommandEmpty>
              ) : null}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  );
};
