// import { DateTimePicker } from "@/_components/date-time-picker";
import { AutocompleteChips } from "@/_components/autocomplete-chips";
import { Button } from "@/_components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from "@/_components/ui/dropdown-menu";
import { downloadData, filterEntities, getUsageDataSummary } from "@/_services";
import { Spinner } from "@/_components/spinner";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { ChevronDown } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useToast } from "@/_hooks/use-toast";

const downloadOptions = [
  { label: "1 File per organization", value: "organization" },
  { label: "1 File per connection", value: "connection" },
  { label: "1 File per meter", value: "meter" },
  { label: "1 File per utility account", value: "utilityAccount" }
  // { label: "1 File per day", value: "4" },
  // { label: "1 File per month", value: "5" },
  // { label: "1 File per year", value: "6" }
];

export const FilterUsageDataPage = () => {
  /** @type {[{[key: string]: {searchTerm: string; items: any[]; selected: {value: string; label: string}[]}}, React.Dispatch<React.SetStateAction<any>>]} */
  const [filters, setFilters] = useState({});
  /** @type {[{from: import("luxon").DateTime | undefined; until: import("luxon").DateTime | undefined}, React.Dispatch<React.SetStateAction<{from: import("luxon").DateTime | undefined; until: import("luxon").DateTime | undefined}>>]} */
  const [dates, setDates] = useState({ from: undefined, until: undefined });
  const [summary, setSummary] = useState("");
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isFiltersLoading, setIsFiltersLoading] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const { toast } = useToast();

  /**
   * @param {string} searchTerm
   * @param {string} filterName
   */
  const filterCall = async (searchTerm, filterName, otherFilters = {}) => {
    setIsFiltersLoading(true);

    const filterObjects = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => {
        const selectedValues = value.selected.map(item => item.value);
        return [key, selectedValues?.length ? selectedValues : undefined];
      })
    );

    const values = await filterEntities({
      searchTerm,
      filterName,
      filterObjects: {
        ...filterObjects,
        ...otherFilters
      }
    });

    setFilters(prev => ({
      ...prev,
      [filterName]: {
        searchTerm,
        items: values[filterName],
        selected: prev[filterName]?.selected || []
      }
    }));

    setIsFiltersLoading(false);
  };

  /**
   * @param {{value: string; label: string}[]} values
   * @param {string} filterName
   */
  const onSelectValue = (values, filterName) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: {
        ...prev[filterName],
        selected: values
      }
    }));

    ["organizations", "connections", "utilityAccounts", "meters"].forEach(
      name =>
        filterCall("", name, {
          [filterName]: values.map(({ value }) => value)
        })
    );
  };

  useEffect(() => {
    filterCall("", "organizations");
    filterCall("", "connections");
    filterCall("", "utilityAccounts");
    // filterCall("", "sites");
    filterCall("", "meters");
  }, []);

  const hasSelectedFilters = useMemo(
    () =>
      Object.values(filters).some(filter => filter.selected.length) ||
      dates.from ||
      dates.until,
    [filters, dates]
  );

  /**
   *
   * @param {string} fileOptions
   */
  const download = fileOptions => {
    setIsLoadingFile(true);
    downloadData({
      fileOptions,
      filterObjects: {
        ...Object.fromEntries(
          Object.entries(filters).map(([key, value]) => {
            const values = value.selected?.map(({ value }) => value);
            return [key, values.length ? values : undefined];
          })
        ),
        from:
          dates.from?.startOf("minute").toFormat("yyyy-MM-dd HH:mm:ss") ||
          undefined,
        until:
          dates.until?.endOf("minute").toFormat("yyyy-MM-dd HH:mm:ss") ||
          undefined
      }
    }).then(url => {
      setIsLoadingFile(false);
      window.open(url, "_blank");
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    setIsSummaryLoading(true);
    const params = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => {
        const values = value.selected?.map(({ value }) => value);

        return [key, values.length ? values : undefined];
      })
    );

    getUsageDataSummary({
      filterObjects: {
        ...params,
        from:
          dates.from?.startOf("minute").toFormat("yyyy-MM-dd HH:mm:ss") ||
          undefined,
        until:
          dates.until?.endOf("minute").toFormat("yyyy-MM-dd HH:mm:ss") ||
          undefined
      }
    }).then(data => {
      setSummary(data);
      setIsSummaryLoading(false);
      setShowSummary(true);
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="bg-white shadow-md rounded px-4 pb-4">
        <div className="sticky top-0 bg-white z-10 py-4">
          <h1 className="font-bold text-2xl ">Usage Data</h1>
        </div>
        <form className="grid gap-4" onSubmit={onSubmit}>
          <div>
            <label>Organizations</label>
            <div>
              <AutocompleteChips
                items={(filters?.organizations?.items || []).map(org => ({
                  label: org.organizationName,
                  value: org.organizationId
                }))}
                selectedValues={filters?.organizations?.selected || []}
                searchValue={filters?.organizations?.searchTerm || ""}
                onSearchValueChange={value =>
                  filterCall(value, "organizations")
                }
                placeholder="Search organizations..."
                onChange={values => {
                  onSelectValue(values, "organizations");
                }}
                isLoading={isFiltersLoading}
              />
            </div>
          </div>
          <div>
            <label>Connection labels</label>
            <div>
              <AutocompleteChips
                items={(filters?.connections?.items || []).map(conn => ({
                  label: conn.label,
                  value: conn.connectionId
                }))}
                selectedValues={filters?.connections?.selected || []}
                searchValue={filters?.connections?.searchTerm || ""}
                onSearchValueChange={value => filterCall(value, "connections")}
                placeholder="Search connections..."
                onChange={values => {
                  onSelectValue(values, "connections");
                }}
                isLoading={isFiltersLoading}
              />
            </div>
          </div>

          <div>
            <label>Utility Accounts</label>
            <div>
              <AutocompleteChips
                items={(filters?.utilityAccounts?.items || []).map(utility => ({
                  label: utility.utilityAccountName,
                  value: utility.utilityAccountId
                }))}
                selectedValues={filters?.utilityAccounts?.selected || []}
                searchValue={filters?.utilityAccounts?.searchTerm || ""}
                onSearchValueChange={value =>
                  filterCall(value, "utilityAccounts")
                }
                placeholder="Search utility account..."
                onChange={values => {
                  onSelectValue(values, "utilityAccounts");
                }}
                isLoading={isFiltersLoading}
              />
            </div>
          </div>

          <div>
            <label>Meters</label>
            <div>
              <AutocompleteChips
                items={(filters?.meters?.items || []).map(meter => ({
                  label: meter.label,
                  value: meter.meterId
                }))}
                selectedValues={filters?.meters?.selected || []}
                searchValue={filters?.meters?.searchTerm || ""}
                onSearchValueChange={value => filterCall(value, "meters")}
                placeholder="Search meters..."
                onChange={values => {
                  onSelectValue(values, "meters");
                }}
                isLoading={isFiltersLoading}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label>From</label>
              <MobileDateTimePicker
                className="w-full [&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-[4.35px] [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                onChange={() => {}}
                onAccept={value => {
                  if (!value) return;
                  setDates(dates => ({ ...dates, from: value }));
                }}
                value={dates.from}
              />
            </div>

            <div>
              <label>To</label>
              <MobileDateTimePicker
                className="w-full [&>div>input]:text-sm [&>div>input]:leading-5 [&>div>input]:py-[4.35px] [&>div>input]:px-2 [&>div>input]:min-h-8 [&>div>input]:placeholder:text-sm [&>div>input]:placeholder:leading-5"
                onChange={() => {}}
                onAccept={value => {
                  if (!value) return;
                  setDates(dates => ({ ...dates, until: value }));
                }}
                value={dates.until}
              />
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div>
              Please click on <b>Submit</b> to search data. You have to select
              at least 1 filter
            </div>
            <Button
              type="submit"
              disabled={isSummaryLoading || !hasSelectedFilters}
            >
              {isSummaryLoading ? <Spinner /> : "Submit"}
            </Button>
          </div>
        </form>
      </div>

      {showSummary && (
        <div className="bg-white shadow-md rounded p-4 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <div>
              <p>{summary}</p>
            </div>
            <div className="flex items-center justify-start">
              <Button
                className="rounded-none rounded-l-md"
                onClick={() => download("meter")}
                disabled={isLoadingFile}
              >
                {isLoadingFile ? <Spinner /> : "Download (by meter)"}
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    className="rounded-none rounded-r-md bg-black"
                    disabled={isLoadingFile}
                  >
                    <ChevronDown />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  {downloadOptions.map(option => (
                    <DropdownMenuLabel asChild key={option.value}>
                      <Button
                        variant="ghost"
                        className="w-full justify-start font-normal"
                        onClick={() => download(option.value)}
                        disabled={isLoadingFile}
                      >
                        {option.label}
                      </Button>
                    </DropdownMenuLabel>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
          <div className="flex justify-start items-center gap-2">
            <div>URL:</div>

            <div className="truncate flex-shrink">
              https://api.getenpowered.com/usage-data/usage/this-is-a-test-url
            </div>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://api.getenpowered.com/usage-data/usage/this-is-a-test-url"
                );
                toast({
                  description: "URL copied to clipboard",
                  duration: 1000
                });
              }}
            >
              Copy
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
