import { Badge } from "@/_components/ui/badge";
import { Button } from "@/_components/ui/button";
import { CircleX } from "lucide-react";
import React, { useState } from "react";

import { Command as CommandPrimitive } from "cmdk";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList
} from "@/_components/ui/command";
import { Input } from "@/_components/ui/input";
import {
  Popover,
  PopoverAnchor,
  PopoverContent
} from "@/_components//ui/popover";
import { Skeleton } from "@/_components/ui/skeleton";

/**
 *
 * @param {object} props
 * @param {{value: string, label: string}[]} props.selectedValues
 * @param {string} props.searchValue
 * @param {(value: string, withSelectedItem: boolean) => void} props.onSearchValueChange
 * @param {{value: string, label: string}[]} props.items
 * @param {boolean} [props.isLoading]
 * @param {string} [props.emptyMessage]
 * @param {string} [props.placeholder]
 * @param {(values: {value: string, label: string}[]) => void} [props.onChange]
 * @returns {JSX.Element}
 */
export const AutocompleteChips = ({
  selectedValues,
  searchValue,
  onSearchValueChange,
  items,
  isLoading,
  emptyMessage = "No items.",
  placeholder = "Search...",
  onChange
}) => {
  const [open, setOpen] = useState(false);

  const reset = () => {
    onValueChange("");
  };

  const onInputBlur = () => {
    reset();
    setOpen(false);
  };

  /**
   *
   * @param {string} inputValue
   */
  const onSelectItem = inputValue => {
    // onSearchValueChange(labels[inputValue] ?? "", true);

    const isRepeated = selectedValues.some(({ value }) => value === inputValue);
    if (!isRepeated) {
      const selected = items.find(item => item.value === inputValue);
      onChange?.([...selectedValues, selected]);
    }

    setOpen(false);
    reset();
  };

  const onValueChange = e => {
    onSearchValueChange(e, false);
  };

  const onDeleteValue = value => {
    onChange?.(selectedValues.filter(chip => chip.value !== value));
  };

  return (
    <div className="flex items-center">
      <Popover open={open}>
        <Command shouldFilter={false}>
          <PopoverAnchor asChild>
            <div className="flex justify-start items-center flex-wrap gap-2 border border-gray-500 p-2 rounded-md">
              {selectedValues.map(({ label, value }) => (
                <Badge key={value} className="h-[22.25px]">
                  <span>{label}</span>
                  <Button
                    type="button"
                    variant="ghost"
                    className="ml-2 p-0 h-[13.5px] w-[13.5px]"
                    onClick={() => onDeleteValue(value)}
                  >
                    <CircleX width={13} height={13} />
                  </Button>
                </Badge>
              ))}
              <Input
                placeholder={placeholder}
                value={searchValue}
                onChange={e => onValueChange(e.target.value)}
                onKeyDown={e => setOpen(e.key !== "Escape")}
                onMouseDown={() => setOpen(open => !!searchValue || !open)}
                onFocus={() => {
                  setOpen(true);
                }}
                onBlur={onInputBlur}
                className="py-0 h-auto grow shrink border-none w-auto"
              />
            </div>
          </PopoverAnchor>
          {!open && <CommandList aria-hidden="true" className="hidden" />}
          <PopoverContent
            asChild
            onOpenAutoFocus={e => e.preventDefault()}
            onInteractOutside={e => {
              if (
                e.target instanceof Element &&
                e.target.hasAttribute("cmdk-input")
              ) {
                e.preventDefault();
              }
            }}
            className="w-[--radix-popover-trigger-width] p-0"
          >
            <CommandList>
              {isLoading && (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-6 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              )}
              {items.length > 0 && !isLoading ? (
                <CommandGroup>
                  {items
                    .filter(
                      ({ value }) =>
                        !selectedValues.some(
                          ({ value: selectedValue }) => value === selectedValue
                        )
                    )
                    .map(option => (
                      <CommandItem
                        key={option.value}
                        value={option.value}
                        onMouseDown={e => e.preventDefault()}
                        onSelect={onSelectItem}
                      >
                        {/* <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          selectedValue === option.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      /> */}
                        {option.label}
                      </CommandItem>
                    ))}
                </CommandGroup>
              ) : null}
              {!isLoading ? (
                <CommandEmpty>{emptyMessage ?? "No items."}</CommandEmpty>
              ) : null}
            </CommandList>
          </PopoverContent>
        </Command>
      </Popover>
    </div>
  );
};
