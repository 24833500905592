import "./CPEventForm.css";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Button,
  HelperText,
  Select,
  Spinner,
  SplitButtonDropdown
} from "@enpowered/ui";
import { DateTime } from "luxon";
import { CPHour } from "./CPHour";
import classNames from "classnames";
import { EventStatus } from "./EventStatus";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { EditableField } from "@/_components/EditableField";

import { InputAdornment } from "@mui/material";
import { useEnumerateMarketDatasets, useGetProgramMeasures } from "@/_hooks";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAvailableActions,
  getNewStatus
} from "@/_utils/energy-programs.utils";
import _ from "lodash";
import {
  getHighestEnpoweredPeak,
  getInternalCPMeasure
} from "@/_utils/coincident-peaks.utils";

yup.addMethod(yup.array, "validRanking", function (message) {
  return this.test("is-ranking-valid", message, function (hours) {
    const { createError, path } = this;

    // check that ranked hours are correct
    // each rank number needs to be next to a lower rank
    // 3 1 2 4 5
    const isRankedValid = hours.every((ph, index) => {
      if (ph.rank === 0) return false;

      const isDuplicated = hours.filter(p => p.rank === ph.rank).length > 1;

      if (ph.rank === 1 && !isDuplicated) return true;

      let previousRankIsLower = false;
      let nextRankIsLower = false;

      if (index > 0) {
        previousRankIsLower = hours[index - 1].rank < ph.rank;
      }

      if (hours.length - 1 > index) {
        nextRankIsLower = hours[index + 1].rank < ph.rank;
      }

      const valid = previousRankIsLower || nextRankIsLower;
      return valid && !isDuplicated;
    });
    return isRankedValid || createError({ path, message });
  });
});

yup.addMethod(yup.array, "validPrimaryHours", function (message) {
  return this.test("is-primary-hours-valid", message, function (hours) {
    const { createError, path } = this;

    // Primary hours (checked ones) have to be adjacent
    // and to start in rank 1 and ascend through each consecutive rank
    // O X X O O
    // Ids have to be consecutive
    const primaryHoursLength = hours.filter(ph => ph.isPriorityHour).length;
    const isPrimaryHoursValid = Array(primaryHoursLength)
      .fill(undefined)
      .every((_, index) => {
        const primaryHourFound = !!hours.find(
          ph => ph.rank === index + 1 && ph.isPriorityHour
        );

        return primaryHourFound;
      });

    return (
      (primaryHoursLength > 0 && isPrimaryHoursValid) ||
      createError({ path, message })
    );
  });
});

const schema = yup.object({
  programEventId: yup.string(),
  energyProgramId: yup.string().required(),
  cpConfidenceLevel: yup.string().required(),
  cpThreshold: yup.number().required(),
  cpPeakMWValue: yup.number().required(),
  cpForecastedPeakDemand: yup.number().required(),
  status: yup
    .string()
    .oneOf(["MAYBE", "NO_CALL", "CALLED", "CANCELLED", ""])
    .required(),
  eventIntervals: yup
    .array(
      yup.object({
        timestamp: yup.string().required(),
        rank: yup.number().required(),
        isPriorityHour: yup.boolean()
      })
    )
    .min(1)
    // @ts-ignore
    .validRanking("Peak hours are ranked incorrectly")
    .validPrimaryHours("Primary hours are incorrect")
    .required()
});

const STATUS = {
  MAYBE: "Maybe",
  CALLED: "Called"
};

const CONFIDENCE = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low"
};

const getDayRange = (date, timezone) =>
  date && timezone
    ? [
        date.setZone(timezone).startOf("day").toUTC().toISO(),
        date.setZone(timezone).endOf("day").toUTC().toISO()
      ]
    : [undefined, undefined];

/**
 *
 * @param {object} props
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {import("@/_services").ProgramEvent} props.programEvent
 * @param {() => any} props.onCancel
 * @param {import("react-query").UseMutateFunction<any, any, Partial<import("@/_services").ProgramEvent>, unknown>} props.assertProgramEvent
 * @param {boolean} [props.isLoading]
 * @param {import("@/_services").JsonRpcError} [props.error]
 * @returns {JSX.Element}
 */

export const CPEventForm = ({
  energyProgram,
  programEvent,
  assertProgramEvent,
  onCancel,
  isLoading: isLoadingExternally = false,
  error
}) => {
  const [date, setDate] = useState(
    programEvent
      ? DateTime.fromISO(programEvent.eventIntervals[0].timestamp, {
          zone: energyProgram.timezone
        })
      : null
  );
  const [intervalStartTime, setIntervalStartTime] = useState(
    programEvent
      ? DateTime.fromISO(programEvent.eventIntervals[0].timestamp)
      : null
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    programEvent
      ? DateTime.fromISO(
          programEvent.eventIntervals.slice(-1)[0].timestamp
        ).plus({ hour: 1 })
      : null
  );
  const [disabledFields, setDisabledFields] = useState(true);
  const [startTime, endTime] = getDayRange(date, energyProgram?.timezone);

  const isAdding = !programEvent?.programEventId;
  const { timezone } = energyProgram;

  const {
    data: { items: [marketDataset] } = { items: [] },
    isLoading: isMarketDatasetLoading
  } = useEnumerateMarketDatasets({ id: energyProgram.programId });

  const {
    isLoading: isMeasuresLoading,
    data: measures = [],
    isFetched: isMeasuresFetched
  } = useGetProgramMeasures(marketDataset, startTime, endTime);

  const enpoweredPeakEstimate = isMeasuresLoading
    ? []
    : getInternalCPMeasure(measures, marketDataset, "enpoweredPeakEstimate", 1);

  const administratorPeakEstimate = isMeasuresLoading
    ? []
    : getInternalCPMeasure(
        measures,
        marketDataset,
        "administratorPeakEstimate"
      );

  const estimatedPeak = getHighestEnpoweredPeak(
    enpoweredPeakEstimate[0],
    administratorPeakEstimate[0]
  );

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    control,
    watch,
    trigger,
    formState: { isValid, errors, isDirty }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      programEventId: programEvent?.programEventId || undefined,
      energyProgramId: energyProgram?.programId || "",
      cpConfidenceLevel: programEvent?.cpConfidenceLevel || "",
      cpThreshold: programEvent?.cpThreshold || 0,
      cpPeakMWValue: programEvent?.cpPeakMWValue || 0,
      cpForecastedPeakDemand: programEvent?.cpForecastedPeakDemand || 0,
      status: programEvent?.status || "",
      eventIntervals:
        /** @type {import("@/_services").CPHour[]}*/ programEvent?.eventIntervals ||
        []
    }
  });

  const {
    fields: fieldsInterval,
    update: updateCPHour,
    replace: replaceCPHours
  } = useFieldArray({
    control,
    name: "eventIntervals"
  });

  const onSubmit = values => {
    // validate that the submit button has been clicked (not status change)
    if (!isValid || !isDirty) return;

    // If it is editing, ther is a validation that you should not send the energyProgramId
    assertProgramEvent(isAdding ? values : _.omit(values, ["energyProgramId"]));
  };

  const onTransition = action =>
    assertProgramEvent({
      programEventId: programEvent.programEventId,
      status: getNewStatus(programEvent.status, action)
    });

  // Check if fields are disabled
  useEffect(() => {
    setDisabledFields(!date || !watch("status"));
  }, [date, watch("status")]);

  // When Intervals change (intervalStartTime and intervalEndTime)
  useEffect(() => {
    if (!intervalStartTime || !intervalEndTime) return;

    if (!isAdding && !!programEvent) {
      const defaultStart = DateTime.fromISO(
        programEvent.eventIntervals[0].timestamp
      );
      const defaultEnd = DateTime.fromISO(
        programEvent.eventIntervals.slice(-1)[0].timestamp
      ).plus({ hour: 1 });

      if (
        defaultStart.equals(intervalStartTime) &&
        defaultEnd.equals(intervalEndTime)
      ) {
        replaceCPHours(programEvent.eventIntervals);
        return;
      }
    }

    /** @type {number} */
    const diff = intervalEndTime
      .diff(intervalStartTime, "hours")
      .toObject().hours;

    /** @type {import("@/_services").CPHour[]} */
    const intervals = Array(diff)
      .fill(undefined)
      .map((_, index) => ({
        timestamp: intervalStartTime.plus({ hour: index }).toISO(),
        isPriorityHour: false,
        rank: 0
      }));

    replaceCPHours(intervals);
  }, [
    intervalStartTime,
    intervalEndTime,
    setValue,
    replaceCPHours,
    isAdding,
    programEvent
  ]);

  useEffect(() => {
    if (!isMeasuresFetched || !estimatedPeak) return;
    if (programEvent) return;

    setValue("cpForecastedPeakDemand", estimatedPeak.maxHour.value);
  }, [isMeasuresFetched, estimatedPeak, setValue, programEvent]);

  const isLoading =
    isLoadingExternally || isMarketDatasetLoading || isMeasuresLoading;

  return (
    <div className="cp-event-form p-4 w-full">
      <h2 className="mb-2">
        <span className="font-bold">{energyProgram.programAdministrator}</span>{" "}
        - {energyProgram.name}
      </h2>
      <div className="border rounded-md border-en-yellow-500 shadow-md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between p-4 border-en-gray-100 border-b ">
            {isAdding ? (
              <div className="flex justify-start gap-4">
                <MobileDatePicker
                  className="w-52"
                  onChange={() => {}}
                  onAccept={date =>
                    setDate(date.setZone(timezone).startOf("day"))
                  }
                  format="yyyy-MM-dd"
                  disablePast
                  value={date}
                />
                <Select
                  name="status"
                  className="block w-full text-sm"
                  {...register("status")}
                >
                  <option value="">Status</option>
                  {Object.keys(STATUS).map(key => (
                    <option key={key} value={key}>
                      {STATUS[key]}
                    </option>
                  ))}
                </Select>
              </div>
            ) : (
              <div className="flex gap-4 items-center justify-start">
                <EventStatus status={getValues("status")} />
                <span className="font-bold text-sm">
                  {date?.toFormat("ccc, LLL d")}
                </span>
              </div>
            )}
            <div className="flex justify-end gap-4 items-center">
              {isLoading && (
                <div className="w-8 h-full pt-2">
                  <Spinner />
                </div>
              )}
              <div>
                {(isDirty || !programEvent) && (
                  <>
                    {isValid && (
                      <Button
                        type="submit"
                        size="narrow"
                        className="rounded-r-none text-sm"
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      onClick={() => onCancel && onCancel()}
                      className={classNames("text-sm", {
                        "rounded-l-none": isValid
                      })}
                      type="reset"
                      size="narrow"
                      theme="dark"
                    >
                      X
                    </Button>
                  </>
                )}

                {!!programEvent && !isDirty && (
                  <SplitButtonDropdown
                    className="font-bold text-sm grid min-w-28 grid-cols-[1fr_auto]"
                    confirm
                    onConfirm={onTransition}
                    getOptionLabel={opt => opt}
                    options={getAvailableActions(programEvent.status)}
                    onClick={() => {}}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="p-4 flex justify-start gap-4">
            <div className={classNames("w-40", { disabled: disabledFields })}>
              <label>Confidence</label>
              <Controller
                control={control}
                name="cpConfidenceLevel"
                render={({ field }) => (
                  <Select
                    name={field.name}
                    value={field.value}
                    className="block w-full text-sm"
                    disabled={disabledFields}
                    onChange={field.onChange}
                  >
                    <option value="">--</option>
                    {Object.keys(CONFIDENCE).map(key => (
                      <option key={key} value={key}>
                        {CONFIDENCE[key]}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </div>
            <div style={{ width: "150px" }}>
              <label>Forecast</label>
              <Controller
                control={control}
                name="cpForecastedPeakDemand"
                render={({ field }) => (
                  <EditableField
                    postfix="MW"
                    value={field.value.toString()}
                    placeholder=""
                    useLocale={true}
                    onEditComplete={field.onChange}
                    showEditandSubmitIcons={false}
                    Label={({
                      value,
                      useLocale,
                      disabled,
                      onClick,
                      postfix
                    }) => (
                      <span
                        onClick={() => !disabled && onClick && onClick()}
                        className={classNames(
                          "p-2 w-full border rounded-md text-sm block",
                          {
                            "border-en-gray-600 text-en-gray-900":
                              !disabledFields,
                            "border-en-gray-100 text-en-gray-100":
                              disabledFields
                          }
                        )}
                      >{`${
                        useLocale
                          ? // @ts-ignore
                            isNaN(value)
                            ? value.toLocaleString()
                            : parseInt(value).toLocaleString()
                          : value
                      } ${postfix || ""}`}</span>
                    )}
                    disabled={disabledFields}
                  />
                )}
              />
            </div>
            <div className="flex flex-col">
              <label>Start Time</label>
              <MobileTimePicker
                onChange={() => {}}
                onAccept={time => {
                  if (!time || !time.isValid) return;
                  const newTime = time
                    .set({
                      day: date.day,
                      month: date.month,
                      year: date.year
                    })
                    .startOf("hour");
                  setIntervalStartTime(newTime);
                }}
                value={intervalStartTime}
                ampm={true}
                ampmInClock={true}
                format={`h a`}
                views={["hours"]}
                disabled={disabledFields}
                maxTime={intervalEndTime?.minus({ hour: 1 })}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {DateTime.now().setZone(timezone).toFormat("ZZZZ")}
                        </InputAdornment>
                      )
                    }
                  }
                }}
                timezone={timezone}
              />
            </div>
            <div className="flex flex-col">
              <label>End Time</label>
              <MobileTimePicker
                onChange={() => {}}
                onAccept={time => {
                  if (!time || !time.isValid) return;
                  const newTime = time
                    .set({
                      day: date.day,
                      month: date.month,
                      year: date.year
                    })
                    .startOf("hour");
                  setIntervalEndTime(newTime);
                }}
                value={intervalEndTime}
                ampm={true}
                ampmInClock={true}
                format="h a"
                views={["hours"]}
                disabled={disabledFields}
                minTime={intervalStartTime?.plus({ hours: 1 })}
                timezone={timezone}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {DateTime.now().setZone(timezone).toFormat("ZZZZ")}
                        </InputAdornment>
                      )
                    }
                  }
                }}
              />
            </div>
          </div>
          <div className="px-4 pb-4">
            <div className="text-sm font-bold">Peak hours</div>
            <p className="text-sm">
              Rank the hours, and use the check box for high priority hours
            </p>
            <div className="flex flex-col gap-1 mt-2">
              {!!errors.eventIntervals && (
                <HelperText valid={false}>
                  {errors.eventIntervals.message}
                </HelperText>
              )}
            </div>
            <div className="flex flex-wrap justify-start items-start gap-2 mt-4">
              {!!fieldsInterval.length &&
                fieldsInterval.map(
                  /**
                   * @param {{id: string} & import("@/_services").CPHour} field
                   * @param {number} index
                   */
                  (field, index) => (
                    <CPHour
                      key={field.id}
                      timestamp={field.timestamp}
                      rank={field.rank}
                      isPriorityHour={field.isPriorityHour}
                      length={fieldsInterval.length}
                      onChange={cpHour => {
                        updateCPHour(index, cpHour);
                        trigger("eventIntervals");
                      }}
                    />
                  )
                )}

              {(!fieldsInterval.length || disabledFields) && (
                <CPHour
                  timestamp={""}
                  rank={0}
                  isPriorityHour={false}
                  length={0}
                  disabled={true}
                />
              )}
            </div>
            <div className="flex justify-end">
              <HelperText valid={false}>{error?.data.description}</HelperText>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

CPEventForm.propTypes = {
  energyProgram: PropTypes.object,
  isAdding: PropTypes.bool,
  onCancel: PropTypes.func,
  getProgramMeasures: PropTypes.func,
  assertProgramEvent: PropTypes.func,
  enumerateMarketDatasetService: PropTypes.func
};

/**
 * @typedef PeakHour
 * @property {object} startTime
 * @property {object} endTime
 * @property {boolean} selected
 * @property {number} rank
 * @property {boolean} enabled
 * @property {number} id
 */
