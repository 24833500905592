import { Spinner } from "@enpowered/ui";
import { useVirtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useRef } from "react";

/**
 * @param {object} props
 * @param {import("@/_services").ProgramNotification[]} props.notifications
 * @param {() => void} props.getMoreData
 * @param {boolean} [props.isFetchingNextPage]
 * @param {(notification: import("@/_services").ProgramNotification) => void} props.onSelectNotification
 * @param {string} props.selectedNotficationId
 * @param {boolean} [props.hasNextPage]
 * @returns {JSX.Element}
 */
export const NotificationsList = ({
  notifications,
  getMoreData,
  isFetchingNextPage = false,
  onSelectNotification,
  selectedNotficationId,
  hasNextPage = false
}) => {
  const listRef = useRef(/** @type {HTMLDivElement} */ null);

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? notifications.length + 1 : notifications.length,
    estimateSize: () => 70, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => listRef.current,
    overscan: 5
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= notifications.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      getMoreData();
    }
  }, [
    hasNextPage,
    getMoreData,
    notifications.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems()
  ]);

  return (
    <div
      ref={listRef}
      className="overflow-y-auto rounded custom-scroller pr-4"
      style={{ height: "calc(100vh - 120px)" }}
    >
      <div
        className="max-h-full flex flex-col gap-2 relative"
        style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
      >
        {rowVirtualizer.getVirtualItems().map(virtualRow => {
          const isLoaderRow = virtualRow.index > notifications.length - 1;
          const notification = notifications[virtualRow.index];

          if (isLoaderRow) {
            return (
              <div
                key="loading-row"
                className="flex justify-center items-center"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`
                }}
              >
                {hasNextPage ? <Spinner /> : "Nothing more to load"}
              </div>
            );
          }

          return (
            <NotificationListItem
              key={notification.programNotificationId}
              notification={notification}
              isSelected={
                notification?.programNotificationId === selectedNotficationId
              }
              onClick={onSelectNotification}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").ProgramNotification} props.notification
 * @param {(notification: import("@/_services").ProgramNotification) => void} props.onClick
 * @param {boolean} [props.isSelected]
 * @param {object} [props.style]
 * @returns {JSX.Element}
 */
const NotificationListItem = ({
  notification,
  isSelected,
  onClick,
  style = {}
}) => {
  const itemClassName = useMemo(
    () =>
      classNames(
        "event flex-none",
        "px-4 py-3",
        "shadow-md border-2 border-solid rounded-md",
        "first:mt-0 last:mb-0",
        "cursor-pointer",
        {
          "bg-en-yellow-300 border-en-yellow-700": isSelected,
          "bg-white border-en-gray-200 hover:bg-yellow-400": !isSelected
        }
      ),
    [isSelected]
  );

  const { isCancelled, dateToShow, energyProgram } = useMemo(() => {
    const {
      notificationScope: {
        programEvents: [programEvent]
      },
      schedule
    } = notification;
    const energyProgram =
      programEvent?.split("__").length === 3
        ? programEvent?.split("__").splice(-1)[0]
        : programEvent?.split("__").splice(-3)[0];

    const scheduled = DateTime.fromISO(schedule);
    // const now = DateTime.now();
    const dateToShow = !scheduled.isValid
      ? schedule
      : scheduled.diffNow().as("hours") > -12
        ? scheduled.toRelative({ style: "short" })
        : scheduled.diffNow().as("years") > -1
          ? scheduled.toFormat("LLL dd, HH:mm a")
          : scheduled.toLocaleString(DateTime.DATE_FULL);

    const isCancelled = notification.status === "CANCELLED";

    return { isCancelled, dateToShow, energyProgram };
  }, [notification]);

  return (
    <div style={style} className="py-2">
      <div className={itemClassName} onClick={() => onClick(notification)}>
        <div className="flex justify-between items-center">
          <span className="font-bold uppercase">{energyProgram}</span>
          <span
            className={classNames("text-xs text-right", {
              "text-en-red": isCancelled
            })}
          >
            {isCancelled ? "CANCELLED" : dateToShow}
          </span>
        </div>
        <div className="truncate">
          {notification.label ? notification.label : "No label"}
        </div>
      </div>
    </div>
  );
};
