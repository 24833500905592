import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

/**
 *
 * @param {object} props
 * @param {boolean} [props.disabled]
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {string} [props.icon]
 * @param {string} props.text
 * @param {boolean} [props.displayAction]
 * @param {any} [props.actionBody]
 * @returns {JSX.Element}
 */
export const ActionButton = ({
  disabled,
  onClick,
  icon,
  text,
  displayAction,
  actionBody
}) => {
  const buttonClass = classNames(["rounded", "ml-2"], {
    "px-3 py-1 shadow-md border-2 border-solid border-gray-500 rounded-md font-bold text-lg":
      true,
    "bg-white hover:bg-yellow-400": !disabled,
    "bg-en-gray-200 text-en-gray-500 cursor-not-allowed": disabled
  });
  return (
    <div>
      <button onClick={onClick} disabled={disabled} className={buttonClass}>
        <i className={`fas ${icon ? icon : "fa-plus"} mr-2`} />
        {text}
      </button>
      {displayAction ? actionBody : null}
    </div>
  );
};

ActionButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  displayAction: PropTypes.bool,
  onClick: PropTypes.func,
  actionBody: PropTypes.element,
  disabled: PropTypes.bool
};
