import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  HelperText,
  Modal,
  ModalContent,
  Spinner
} from "@enpowered/ui";
import { DateTime, Duration } from "luxon";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { InputAdornment } from "@mui/material";

/**
 *
 * @param {object} props
 * @param {import("@/_services").ProgramEvent[]} props.programEvents
 * @param {import("@/_services").EnergyProgram} props.energyProgram
 * @param {boolean} props.isOpen
 * @param {boolean} props.isLoading
 * @param {(() => any)} props.onClose
 * @param {import("react-query").UseMutateFunction<any, any, import("@/_services").ProgramEvent[], unknown>} props.assertProgramEvents
 * @param {string} props.error
 * @param {string} [props.title]
 * @param {boolean} [props.activate]
 * @returns
 */

export const DRActivationModal = ({
  programEvents,
  energyProgram,
  isOpen,
  onClose,
  assertProgramEvents,
  error,
  isLoading,
  title,
  activate
}) => {
  const drInterval =
    /** @type {import("@/_services").DRInterval} */ programEvents[0]
      .eventIntervals[0];

  const [startTime, setStartTime] = useState(
    DateTime.fromISO(drInterval.timestamp, {
      zone: energyProgram.timezone
    })
  );

  const [duration, setDuration] = useState(
    Duration.fromISO(drInterval.duration)
  );

  const [endTime, setEndTime] = useState(startTime.plus(duration));

  const onSubmit = e => {
    e.preventDefault();

    assertProgramEvents(
      programEvents.map(({ programEventId, status }) => ({
        programEventId,
        eventIntervals: [
          {
            timestamp: startTime.toISO(),
            duration: duration.toISO()
          }
        ],
        ...(activate ? { status: "CALLED" } : { status })
      }))
    );
  };

  const MemoizedModalContent = useCallback(
    ({ children, ...props }) => (
      <ModalContent
        {...props}
        className={{ "bg-en-yellow-100": false, "bg-white": true }}
      >
        {children}
      </ModalContent>
    ),
    []
  );

  useEffect(() => {
    if (!startTime || !endTime) return;
    setDuration(endTime.diff(startTime, "hours"));
  }, [startTime, endTime]);

  return (
    <Modal isOpen={isOpen} size={"md"} ModalContent={MemoizedModalContent}>
      <div>
        <h2 className="font-bold text-xl">
          {title || "Set Activation Window"}
        </h2>
        <form onSubmit={onSubmit}>
          <div className="flex flex-row gap-4 p-4">
            <div className="flex flex-col">
              <label>Start Time</label>
              <MobileTimePicker
                onChange={() => {}}
                onAccept={time => {
                  if (!time || !time.isValid) return;
                  setStartTime(time);
                }}
                value={startTime}
                ampm={true}
                ampmInClock={true}
                format="h a"
                views={["hours"]}
                maxTime={endTime?.minus({ hour: 1 })}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {DateTime.now()
                            .setZone(energyProgram.timezone)
                            .toFormat("ZZZZ")}
                        </InputAdornment>
                      )
                    }
                  }
                }}
              />
            </div>
            <div className="flex flex-col">
              <label>End Time</label>
              <MobileTimePicker
                onChange={() => {}}
                onAccept={time => {
                  if (!time || !time.isValid) return;
                  setEndTime(time);
                }}
                value={endTime}
                ampm={true}
                ampmInClock={true}
                format="h a"
                views={["hours"]}
                minTime={startTime?.plus({
                  hours: 1
                })}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {DateTime.now()
                            .setZone(energyProgram.timezone)
                            .toFormat("ZZZZ")}
                        </InputAdornment>
                      )
                    }
                  }
                }}
              />
            </div>
          </div>
          <HelperText valid={false}>{error}</HelperText>
          <div className="flex justify-end items-center py-4 gap-4">
            <Button
              size="narrow"
              theme="transparent"
              type="button"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="narrow"
              theme="dark"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DRActivationModal.propTypes = {
  programEvents: PropTypes.array,
  energyProgram: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  assertProgramEvents: PropTypes.func,
  error: PropTypes.string
};
