import { Button, Spinner } from "@enpowered/ui";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Account name is required."),
  address: Yup.object({
    streetAddress: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    postalCode: Yup.string().required("Postal code is required")
  })
});

export const AccountForm = ({
  error,
  isLoading,
  account,
  onEdit,
  isInEditMode,
  isNewAccount,
  onSubmit,
  onCancel,
  title
}) => {
  const submitChanges = args => {
    // This is where we would call out to make the updates

    onEdit && onEdit(false);
    return onSubmit(args);
  };

  const enableEditing = () => {
    onEdit(true);
  };

  account = account || { name: "", address: {} };

  const isDisabled = !(isNewAccount || isInEditMode);

  const fieldClass = classNames(
    "appearance-none border rounded w-full text-gray-700 leading-tight",
    "py-2 px-4 mb-2",
    "focus:outline-none",
    {
      "input mb-2 truncate": true,
      "border-white bg-transparent px-0": isDisabled,
      "px-4": !isDisabled
    }
  );

  const handleCancel = e => {
    e.preventDefault();
    onEdit && onEdit(false);
    onCancel && onCancel();
  };

  const wrapperClass = classNames({
    "bg-white pb-4 rounded-md": true,
    "border-solid border-2 border-en-gray-100": !isNewAccount
  });

  return (
    <div className={wrapperClass}>
      {isNewAccount && (
        <h2 className="px-4 pt-4 text-xl font-bold">
          {title || "Add an Account"}
        </h2>
      )}
      <Formik
        initialValues={account}
        enableReinitialize
        onSubmit={submitChanges}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, errors }) => (
          <Form className="px-4 pt-4" onSubmit={handleSubmit}>
            <div>
              <label className="text-lg text-gray-800 font-bold">Account</label>
              {errors.name && (
                <label className="block text-xs text-red-800">
                  {errors.name.toString()}
                </label>
              )}
              <Field
                className={fieldClass}
                name="name"
                placeholder="Name"
                type="text"
                disabled={isDisabled}
              />
            </div>
            <fieldset className="py-4">
              <label className="text-lg text-gray-800 font-bold">Address</label>
              <div>
                <label className="text-sm text-gray-500">Address</label>
                <Field
                  className={fieldClass}
                  name="address.streetAddress"
                  placeholder="Address"
                  type="text"
                  disabled={isDisabled}
                />
                {
                  // @ts-ignore
                  errors.address?.streetAddress && (
                    <label className="block text-xs text-red-800">
                      {
                        // @ts-ignore
                        errors.address?.streetAddress.toString()
                      }
                    </label>
                  )
                }
              </div>
              <div>
                <div className="w-1/2 inline-block pr-2">
                  <label className="text-sm text-gray-500">City</label>
                  <Field
                    className={fieldClass}
                    name="address.city"
                    placeholder="City"
                    type="text"
                    disabled={isDisabled}
                  />
                  {
                    // @ts-ignore
                    errors.address?.city && (
                      <label className="block text-xs text-red-800">
                        {
                          // @ts-ignore
                          errors.address?.city.toString()
                        }
                      </label>
                    )
                  }
                </div>
                <div className="w-1/2 inline-block">
                  <label className="text-sm text-gray-500">State</label>
                  <Field
                    className={fieldClass}
                    name="address.state"
                    placeholder="State"
                    type="text"
                    disabled={isDisabled}
                  />
                  {
                    // @ts-ignore
                    errors.address?.state && (
                      <label className="block text-xs text-red-800">
                        {
                          // @ts-ignore
                          errors.address?.state.toString()
                        }
                      </label>
                    )
                  }
                </div>
              </div>
              <div>
                <div className="w-1/2 inline-block pr-2">
                  <label className="text-sm text-gray-500">Country</label>
                  <Field
                    className={fieldClass}
                    name="address.country"
                    type="text"
                    placeholder="Country"
                    disabled={isDisabled}
                  />
                  {
                    // @ts-ignore
                    errors.address?.country && (
                      <label className="block text-xs text-red-800">
                        {
                          // @ts-ignore
                          errors.address?.country.toString()
                        }
                      </label>
                    )
                  }
                </div>
                <div className="w-1/2 inline-block">
                  <label className="text-sm text-gray-500">
                    Zip/Postal Code
                  </label>
                  <Field
                    className={fieldClass}
                    name="address.postalCode"
                    type="text"
                    placeholder="Zip/Postal Code"
                    disabled={isDisabled}
                  />
                  {
                    // @ts-ignore
                    errors.address?.postalCode && (
                      <label className="block text-xs text-red-800">
                        {
                          // @ts-ignore
                          errors.address?.postalCode.toString()
                        }
                      </label>
                    )
                  }
                </div>
              </div>
            </fieldset>
            {error && (
              <label className="block text-sm py-2 text-center text-red-800">
                {error?.errors?.[0]?.detail}
              </label>
            )}
            <div className="flex place-content-end">
              {isNewAccount || isInEditMode ? (
                <>
                  <Button
                    theme="none"
                    className="mr-1 inline-block"
                    size="narrow"
                    type="button"
                    // @ts-ignore
                    onClick={handleCancel}
                    disabled={isLoading || isDisabled}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="ml-1"
                    size="narrow"
                    theme="dark"
                    type="submit"
                    disabled={isLoading || isDisabled}
                  >
                    {isLoading ? (
                      <Spinner size={16} className="align-middle" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </>
              ) : undefined}
            </div>
          </Form>
        )}
      </Formik>
      {!isNewAccount && (
        <div className="flex flex-row justify-end px-4">
          {!isInEditMode && (
            <Button
              size="narrow"
              theme="dark"
              // @ts-ignore
              onClick={enableEditing}
              disabled={false}
            >
              Edit
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

AccountForm.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.bool,
  account: PropTypes.object,
  onEdit: PropTypes.func,
  isInEditMode: PropTypes.bool,
  isNewAccount: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string
};
