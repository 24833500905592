import { Autocomplete } from "@/_components/autocomplete";
import { ConfirmationModal } from "@/_components/ConfirmationModal";
import { Spinner } from "@/_components/spinner";
import { Button } from "@/_components/ui/button";
import { Checkbox } from "@/_components/ui/checkbox";
import { FormControl, FormField, FormItem } from "@/_components/ui/form";
import { Input } from "@/_components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/_components/ui/select";
import {
  useEnumerateAllOrgProfiles,
  useEnumerateUtilities,
  useInitiateConnection
} from "@/_hooks";
import { HelperText } from "@enpowered/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { TriangleAlert } from "lucide-react";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

const schema = yup.object().shape({
  organizationName: yup.string().required("Organization is required"),
  organizationId: yup.string(),
  label: yup.string().required("Label is required"),
  utilityId: yup.string(),
  utilityName: yup.string(),
  dataPullSchedule: yup.string().required("Data pull schedule is required"),
  scope: yup.object().shape({
    historyLengthInYears: yup.number().min(0).default(1),
    ongoingInYears: yup.number().min(0).default(10),
    datasets: yup.array().of(yup.string()).required("Select at least 1 Dataset")
  }),
  sendEmail: yup.boolean(),
  contactEmail: yup
    .string()
    .email("Email is wrong")
    .when("sendEmail", {
      is: true,
      then: schema => schema.required("Email is required")
    })
});

const modal = {
  NONE: "none",
  ORGANIZATION: "organization",
  EMAIL: "email",
  SAVED: "saved"
};

export const NewConnectionPage = () => {
  /** @type {[any, React.Dispatch<React.SetStateAction<any>>]} */
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(modal.NONE);
  const navigate = useNavigate();
  const [searchUtility, setSearchUtility] = useState("");
  const [connectionId, setConnectionId] = useState("");

  const {
    data: { items: utilities = [] } = { items: [] },
    isLoading: isUtilitiesLoading
  } = useEnumerateUtilities({
    searchTerm: searchUtility
  });

  const {
    mutate: initiateConnection,
    isLoading: isInitiateConnectionLoading,
    error: initiateConnectionError
  } = useInitiateConnection(({ connectionId }) => {
    setConnectionId(connectionId);
    if (!formData.sendEmail) {
      setShowModal(modal.NONE);
      resetForm(connectionId);
    } else {
      setShowModal(modal.SAVED);
    }
  });

  const {
    data: orgProfilesData = [],
    isLoading: isEnumerateOrgProfilesLoading
  } = useEnumerateAllOrgProfiles();

  const orgProfiles = useMemo(
    () =>
      orgProfilesData.map(({ orgProfileId, name }) => ({
        value: orgProfileId,
        label: name
      })),
    [orgProfilesData]
  );

  const isLoading = isEnumerateOrgProfilesLoading;

  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      organizationId: undefined,
      sendEmail: false,
      scope: {
        historyLengthInYears: 2,
        ongoingInYears: 10,
        datasets: ["ELECTRICITY_USAGE", "BILLING_AND_COSTS"]
      },
      dataPullSchedule: "DAILY"
    }
  });

  const onSubmit = data => {
    setFormData(data);
    if (!data.organizationId) {
      setShowModal(modal.ORGANIZATION);
    } else if (data.sendEmail) {
      setShowModal(modal.EMAIL);
    } else {
      sendData(data);
    }
  };

  const resetForm = id => {
    navigate(`/usage-data/${id}`);
  };

  const sendData = (data = formData) => {
    initiateConnection({
      label: data.label,
      organizationId: data.organizationId,
      organizationName: data.organizationName,
      utilityId: data.utilityId || undefined,
      contactEmail: data.contactEmail || undefined,
      dataPullSchedule: data.dataPullSchedule,
      scope: data.scope
    });
  };

  return (
    <div className="px-4 pb-4">
      <ConfirmationModal
        title="Unknown organization"
        text={`${formData.organizationName} is not a known organization. Do you want to continue?`}
        isOpen={showModal === modal.ORGANIZATION}
        onClose={() => {
          setShowModal(modal.NONE);
        }}
        onConfirm={() => {
          setShowModal(modal.NONE);
          if (formData.linkOrEmail === "email") {
            setShowModal(modal.EMAIL);
          } else {
            sendData();
          }
        }}
        confirmButtonText="Yes"
        cancelButtonText="No"
      />

      <ConfirmationModal
        title="Send email"
        text={`Are you sure you want to send a connection request to ${watch(
          "contactEmail"
        )}?`}
        isOpen={showModal === modal.EMAIL}
        onClose={() => {
          setShowModal(modal.NONE);
        }}
        onConfirm={() => {
          setShowModal(modal.NONE);
          sendData();
        }}
        cancelButtonText="Cancel"
        confirmButtonText="Send"
      />

      <ConfirmationModal
        title="Email has been sent"
        text={`A connection request has been sent to ${watch("contactEmail")}`}
        isOpen={showModal === modal.SAVED}
        onClose={() => {
          setShowModal(modal.NONE);
          resetForm(connectionId);
        }}
        onConfirm={() => {
          setShowModal(modal.NONE);
          resetForm(connectionId);
        }}
        confirmButtonText="OK"
      />

      <div className="bg-white shadow-md rounded p-4">
        <form className="grid gap-4" onSubmit={handleSubmit(onSubmit)}>
          <h1 className="font-bold text-2xl sticky top-0 bg-white z-10">
            New Connection
          </h1>
          {isLoading ? (
            <div className="h-full w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <div className="grid gap-4">
              <div className="grid gap-2">
                <label>Organization</label>
                <div>
                  <Controller
                    name="organizationName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        items={
                          !field.value
                            ? orgProfiles
                            : orgProfiles.filter(
                                ({ label }) =>
                                  label
                                    .toLowerCase()
                                    .indexOf(field.value.toLowerCase()) > -1
                              )
                        }
                        selectedValue={watch("organizationId")}
                        placeholder="Organization Name"
                        onSelectedValueChange={value => {
                          setValue("organizationId", value);
                        }}
                        searchValue={field.value}
                        onSearchValueChange={(value, withSelectedItem) => {
                          if (!withSelectedItem) {
                            setValue("organizationId", undefined);
                          }
                          field.onChange({ target: { value } });
                        }}
                        resetOnBlur={false}
                      />
                    )}
                  />
                  {!watch("organizationId") && !!watch("organizationName") && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>This organization does not exist</span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.organizationName?.message}
                  </HelperText>
                </div>
              </div>

              <div className="grid gap-2">
                <label>Connection label</label>
                <div>
                  <Input placeholder="Label" {...register("label")} />
                  <HelperText valid={false}>{errors.label?.message}</HelperText>
                </div>
              </div>

              <div className="grid gap-2">
                <label>Utility</label>
                <div>
                  <Controller
                    name="utilityName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        items={utilities.map(({ id, name }) => ({
                          value: id,
                          label: name
                        }))}
                        isLoading={isUtilitiesLoading}
                        selectedValue={field.value}
                        placeholder="Utility Name"
                        onSelectedValueChange={value => {
                          setValue("utilityId", value);
                        }}
                        searchValue={field.value}
                        onSearchValueChange={value => {
                          setSearchUtility(value);
                          field.onChange({ target: { value } });
                        }}
                        resetOnBlur={false}
                      />
                    )}
                  />
                  <HelperText valid={false}>
                    {errors.utilityId?.message}
                  </HelperText>
                </div>
              </div>

              <div className="font-semibold text-xl">Scope</div>
              <div className="grid grid-cols-[130px_1fr_130px_1fr] gap-4 items-start">
                <label className="leading-[33.75px]">Historical Data</label>
                <div className="w-full">
                  <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                    <Input
                      type="number"
                      {...register("scope.historyLengthInYears")}
                      // @ts-ignore
                      min={0}
                    />
                    Years
                  </div>
                  {watch("scope.historyLengthInYears") == 0 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>Historical data will not be collected</span>
                    </span>
                  )}
                  {watch("scope.historyLengthInYears") > 2 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>
                        Some utilities may not provide this much historical data
                      </span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.scope?.historyLengthInYears?.message}
                  </HelperText>
                </div>

                <label className="leading-[33.75px]">Ongoing Data</label>
                <div className="w-full">
                  <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                    <Input
                      type="number"
                      {...register("scope.ongoingInYears")}
                      // @ts-ignore
                      min={0}
                    />
                    Years
                  </div>
                  {watch("scope.ongoingInYears") == 0 && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>
                        Usage data will not be pulled on an ongoing basis
                      </span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.scope?.ongoingInYears?.message}
                  </HelperText>
                </div>
              </div>

              <div className="grid grid-cols-[130px_1fr] gap-4 items-center">
                <label className="leading-[33.75px]">Dataset</label>
                <div className="w-full">
                  <div className="w-full flex gap-4">
                    <Controller
                      control={control}
                      render={() => (
                        <label className="flex gap-2 items-center">
                          <Checkbox
                            checked={(watch("scope.datasets") || []).includes(
                              "ELECTRICITY_USAGE"
                            )}
                            onCheckedChange={checked => {
                              const key = "ELECTRICITY_USAGE";
                              const exists = (
                                watch("scope.datasets") || []
                              ).includes(key);

                              if (checked && !exists) {
                                setValue("scope.datasets", [
                                  ...(watch("scope.datasets") || []),
                                  key
                                ]);
                              } else if (!checked && exists) {
                                setValue(
                                  "scope.datasets",
                                  (watch("scope.datasets") || []).filter(
                                    item => item !== key
                                  )
                                );
                              }

                              return;
                            }}
                          />
                          <span>Electricity Usage</span>
                        </label>
                      )}
                      name="scope.datasets"
                    />

                    <Controller
                      control={control}
                      render={() => (
                        <label className="flex gap-2 items-center">
                          <Checkbox
                            checked={(watch("scope.datasets") || []).includes(
                              "BILLING_AND_COSTS"
                            )}
                            onCheckedChange={checked => {
                              const key = "BILLING_AND_COSTS";
                              const exists = (
                                watch("scope.datasets") || []
                              ).includes(key);

                              if (checked && !exists) {
                                setValue("scope.datasets", [
                                  ...(watch("scope.datasets") || []),
                                  key
                                ]);
                              } else if (!checked && exists) {
                                setValue(
                                  "scope.datasets",
                                  (watch("scope.datasets") || []).filter(
                                    item => item !== key
                                  )
                                );
                              }

                              return;
                            }}
                          />
                          <span>Billing and Costs</span>
                        </label>
                      )}
                      name="scope.datasets"
                    />
                  </div>
                  <HelperText valid={false}>
                    {errors.scope?.datasets?.message}
                  </HelperText>
                </div>
              </div>

              <div className="grid grid-cols-[130px_1fr] gap-4 items-start">
                <label className="leading-[33.75px]">Data Pull Schedule</label>
                <FormField
                  name="dataPullSchedule"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <SelectTrigger className="w-[180px]">
                          <FormControl>
                            <SelectValue placeholder="Select ..." />
                          </FormControl>
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {/* <SelectLabel>Data Pull Schedule</SelectLabel> */}
                            <SelectItem value="DAILY">Daily</SelectItem>
                            <SelectItem value="WEEKLY">Weekly</SelectItem>
                            <SelectItem value="MONTHLY">Monthly</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <HelperText valid={false}>
                        {errors.dataPullSchedule?.message}
                      </HelperText>
                    </FormItem>
                  )}
                />
              </div>

              <label className="flex justify-start items-center gap-2">
                <Controller
                  name="sendEmail"
                  control={control}
                  render={({ field }) => (
                    <label className="flex gap-2 items-center">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={checked => {
                          setValue("sendEmail", !!checked);
                        }}
                      />
                      <span>Send email</span>
                    </label>
                  )}
                />
              </label>

              {watch("sendEmail") && (
                <div className="grid gap-4">
                  <label>Contact&apos;s email</label>
                  <div>
                    <Input
                      type="email"
                      placeholder="Email"
                      {...register("contactEmail")}
                    />
                    <HelperText valid={false}>
                      {errors.contactEmail?.message}
                    </HelperText>
                  </div>
                </div>
              )}

              <div className="grid grid-cols-[1fr_auto] items-center gap-4">
                <div>
                  {!!initiateConnectionError &&
                    "There was an error, please Try again in a few minutes"}
                </div>
                <div className="flex items-center gap-4">
                  <Button variant="ghost" type="button">
                    Cancel
                  </Button>
                  <Button disabled={isInitiateConnectionLoading}>
                    {isInitiateConnectionLoading ? (
                      <Spinner />
                    ) : (
                      "Initiate connection"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};
