import { Autocomplete } from "@/_components/autocomplete";
import { Spinner } from "@/_components/spinner";
import { Button } from "@/_components/ui/button";
import { Checkbox } from "@/_components/ui/checkbox";
import { FormControl, FormField, FormItem } from "@/_components/ui/form";
import { Input } from "@/_components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/_components/ui/select";
import {
  useEnumerateAllOrgProfiles,
  useEnumerateConnections,
  useEnumerateUtilities,
  useUpdateConnectionAttributes
} from "@/_hooks";
import { useToast } from "@/_hooks/use-toast";
import { cn } from "@/_utils/cn";
import { ConnectionStatus } from "@/usage-data/components/ConnectionStatus";
import { HelperText } from "@enpowered/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Pencil, Save, TriangleAlert } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";

const schema = yup.object().shape({
  organizationName: yup.string().required("Organization is required"),
  organizationId: yup.string(),
  label: yup.string(),
  utilityId: yup.string(),
  utilityName: yup.string(),
  dataPullSchedule: yup.string().required("Data pull schedule is required"),
  scope: yup.object().shape({
    historyLengthInYears: yup.number().min(0).default(1),
    ongoingInYears: yup.number().min(0).default(10),
    datasets: yup.array().of(yup.string()).required("Select at least 1 Dataset")
  }),
  contactEmail: yup
    .string()
    .email("Email is wrong")
    .when("sendEmail", {
      is: true,
      then: schema => schema.required("Email is required")
    })
});

const dataPullScheduleValues = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTLY: "Monthly"
};

/**
 *
 * @returns {JSX.Element}
 */
export const ConnectionDetailPage = () => {
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [searchUtility, setSearchUtility] = useState("");
  const { toast } = useToast();

  const {
    data: { items: [connection] } = { items: [] },
    isLoading: isConnectionLoading
  } = useEnumerateConnections({ id });

  const {
    data: orgProfilesData = [],
    isLoading: isEnumerateOrgProfilesLoading
  } = useEnumerateAllOrgProfiles();

  const {
    data: { items: [utility] } = { items: [] },
    isLoading: isGetUtilityLoading
  } = useEnumerateUtilities({
    id: connection?.utilityId
  });

  const {
    data: { items: utilities = [] } = { items: [] },
    isLoading: isUtilitiesLoading
  } = useEnumerateUtilities({
    searchTerm: searchUtility
  });

  const orgProfiles = useMemo(
    () =>
      orgProfilesData.map(({ orgProfileId, name }) => ({
        value: orgProfileId,
        label: name
      })),
    [orgProfilesData]
  );

  const {
    mutate: updateConnectionAttributes,
    isLoading: isUpdateConnectionAttributesLoading
  } = useUpdateConnectionAttributes(() => {
    setIsEditing(false);
  });

  const {
    handleSubmit,
    control,
    setValue,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (connection) {
      Object.entries(connection).forEach(([key, value]) =>
        // @ts-ignore
        setValue(key, value)
      );
    }
  }, [connection]);

  useEffect(() => {
    if (utility) setValue("utilityName", utility.name);
  }, [utility]);

  const onSubmit = values => {
    updateConnectionAttributes({
      // label: values.label,
      connectionId: connection.connectionId,
      utilityId: values.utilityId,
      organizationId: values.organizationId || undefined,
      organizationName: values.organizationName,
      dataPullSchedule: values.dataPullSchedule
    });
  };

  const isLoading =
    isConnectionLoading || isEnumerateOrgProfilesLoading || isGetUtilityLoading;

  return (
    <div>
      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!isLoading && !connection && (
        <div className="w-full h-full flex items-center justify-center py-8">
          <b>Connection doesn&apos;t exist</b>
        </div>
      )}
      {!!connection && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-md rounded px-4 pb-4"
        >
          <div className="grid grid-cols-[1fr_auto] gap-4 items-center justify-start sticky top-0 bg-white z-50 py-4">
            <div className="flex justify-start">
              <ConnectionStatus status={connection.status} />
            </div>

            <div className="flex gap-4">
              {isEditing ? (
                <>
                  <Button
                    type="button"
                    variant="ghost"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="flex gap-2 items-center">
                    {isUpdateConnectionAttributesLoading ? (
                      <Spinner className="text-white" />
                    ) : (
                      <span className="flex gap-2 items-center">
                        <Save width={12} height={12} /> Save
                      </span>
                    )}
                  </Button>
                </>
              ) : (
                <>
                  {connection.status === "PENDING" && (
                    <Button>Resend Connection Request</Button>
                  )}
                  {connection.status === "EXPIRED" && (
                    <Button>Re-Authorize Connection</Button>
                  )}
                  <Button
                    type="button"
                    className="flex gap-2 items-center"
                    onClick={() => setIsEditing(true)}
                  >
                    <Pencil width={12} height={12} /> Edit
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-[1fr] gap-4 items-center">
            {isEditing ? (
              <>
                <div>
                  <Controller
                    name="organizationName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        items={
                          !field.value
                            ? orgProfiles
                            : orgProfiles.filter(
                                ({ label }) =>
                                  label
                                    .toLowerCase()
                                    .indexOf(field.value.toLowerCase()) > -1
                              )
                        }
                        selectedValue={watch("organizationId")}
                        placeholder="Organization Name"
                        onSelectedValueChange={value => {
                          setValue("organizationId", value);
                        }}
                        searchValue={field.value}
                        onSearchValueChange={(value, withSelectedItem) => {
                          if (!withSelectedItem) {
                            setValue("organizationId", undefined);
                          }
                          field.onChange({ target: { value } });
                        }}
                        resetOnBlur={false}
                      />
                    )}
                  />
                  {!watch("organizationId") && !!watch("organizationName") && (
                    <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                      <TriangleAlert className="h-4 w-4" />
                      <span>This organization does not exist</span>
                    </span>
                  )}
                  <HelperText valid={false}>
                    {errors.organizationName?.message}
                  </HelperText>
                </div>
                <div>
                  <Input
                    placeholder="Connection label"
                    {...register("label")}
                  />
                  <HelperText valid={false}>{errors.label?.message}</HelperText>
                </div>
              </>
            ) : (
              <>
                <h1 className="font-bold text-2xl sticky top-0 bg-white z-10">
                  {connection.organizationName || "No Organization"}
                </h1>
                <h2 className="font-semibold text-xl sticky top-0 bg-white z-10">
                  {connection.label || "No Label"}
                </h2>
              </>
            )}
          </div>
          <div className="grid grid-cols-[1fr_300px] gap-4 mt-8">
            <div className="grid grid-cols-[180px_1fr] gap-4">
              <label>Date Requested:</label>
              <span className="font-semibold">--</span>

              <label>Last Request sent:</label>
              <span className="font-semibold">--</span>

              <label>Date Authorized:</label>
              <span className="font-semibold">--</span>

              <label className="self-center">Contact&apos;s email:</label>
              {isEditing ? (
                <div>
                  <Input
                    type="email"
                    placeholder="Contact's email"
                    className="w-[180px]"
                    {...register("contactEmail")}
                  />
                  <HelperText valid={false}>
                    {errors.contactEmail?.message}
                  </HelperText>
                </div>
              ) : (
                <span className="font-semibold">
                  {connection.contactEmail || "--"}
                </span>
              )}

              <label>Utility:</label>
              {isEditing ? (
                <div>
                  <Controller
                    name="utilityName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        items={utilities.map(({ id, name }) => ({
                          value: id,
                          label: name
                        }))}
                        isLoading={isUtilitiesLoading}
                        selectedValue={field.value}
                        placeholder="Utility Name"
                        onSelectedValueChange={value => {
                          setValue("utilityId", value);
                        }}
                        searchValue={field.value}
                        onSearchValueChange={value => {
                          setSearchUtility(value);
                          field.onChange({ target: { value } });
                        }}
                        resetOnBlur={false}
                      />
                    )}
                  />
                  <HelperText valid={false}>
                    {errors.utilityId?.message}
                  </HelperText>
                </div>
              ) : (
                <span className="font-semibold">
                  {connection.utilityId ? utility?.name : "--"}
                </span>
              )}

              <label>Scope:</label>
              <ul className="list-disc pl-4 grid gap-2">
                <li>
                  <div className="grid grid-cols-[115px_1fr] gap-4 items-start">
                    <label
                      className={cn({
                        "leading-[33.75px]": isEditing
                      })}
                    >
                      Historical Data:
                    </label>
                    {isEditing ? (
                      <div className="w-full inline">
                        <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                          <Input
                            type="number"
                            {...register("scope.historyLengthInYears")}
                            // @ts-ignore
                            min={0}
                          />
                          Years
                        </div>
                        {watch("scope.historyLengthInYears") == 0 && (
                          <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                            <TriangleAlert className="h-4 w-4" />
                            <span>Historical data will not be collected</span>
                          </span>
                        )}
                        {watch("scope.historyLengthInYears") > 2 && (
                          <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                            <TriangleAlert className="h-4 w-4" />
                            <span>
                              Some utilities may not provide this much
                              historical data
                            </span>
                          </span>
                        )}
                        <HelperText valid={false}>
                          {errors.scope?.historyLengthInYears?.message}
                        </HelperText>
                      </div>
                    ) : (
                      <span className="font-semibold">
                        {connection.scope?.historyLengthInYears || 0} years
                      </span>
                    )}
                  </div>
                </li>
                <li>
                  <div className="grid grid-cols-[115px_1fr] gap-4 items-start">
                    <label
                      className={cn({
                        "leading-[33.75px]": isEditing
                      })}
                    >
                      Ongoing Data:
                    </label>
                    {isEditing ? (
                      <div className="w-full">
                        <div className="grid grid-cols-[100px_1fr] items-center gap-2">
                          <Input
                            type="number"
                            {...register("scope.ongoingInYears")}
                            // @ts-ignore
                            min={0}
                          />
                          Years
                        </div>
                        {watch("scope.ongoingInYears") == 0 && (
                          <span className="text-xs text-yellow-600 flex gap-2 items-center py-2">
                            <TriangleAlert className="h-4 w-4" />
                            <span>
                              Data will not be pulled on an ongoing basis
                            </span>
                          </span>
                        )}
                        <HelperText valid={false}>
                          {errors.scope?.ongoingInYears?.message}
                        </HelperText>
                      </div>
                    ) : (
                      <span className="font-semibold">
                        {connection.scope?.ongoingInYears || 0} years
                      </span>
                    )}
                  </div>
                </li>
                <li>
                  <div className="grid grid-cols-[115px_1fr] gap-4 items-start">
                    <label
                      className={cn({
                        "leading-[33.75px]": isEditing
                      })}
                    >
                      Datasets:
                    </label>

                    {isEditing ? (
                      <div className="w-full">
                        <div className="w-full flex flex-col gap-2">
                          <Controller
                            control={control}
                            render={() => (
                              <label className="flex gap-2 items-center">
                                <Checkbox
                                  checked={(
                                    watch("scope.datasets") || []
                                  ).includes("ELECTRICITY_USAGE")}
                                  onCheckedChange={checked => {
                                    const key = "ELECTRICITY_USAGE";
                                    const exists = (
                                      watch("scope.datasets") || []
                                    ).includes(key);

                                    if (checked && !exists) {
                                      setValue("scope.datasets", [
                                        ...(watch("scope.datasets") || []),
                                        key
                                      ]);
                                    } else if (!checked && exists) {
                                      setValue(
                                        "scope.datasets",
                                        (watch("scope.datasets") || []).filter(
                                          item => item !== key
                                        )
                                      );
                                    }

                                    return;
                                  }}
                                />
                                <span>Electricity Usage</span>
                              </label>
                            )}
                            name="scope.datasets"
                          />

                          <Controller
                            control={control}
                            render={() => (
                              <label className="flex gap-2 items-center">
                                <Checkbox
                                  checked={(
                                    watch("scope.datasets") || []
                                  ).includes("BILLING_AND_COSTS")}
                                  onCheckedChange={checked => {
                                    const key = "BILLING_AND_COSTS";
                                    const exists = (
                                      watch("scope.datasets") || []
                                    ).includes(key);

                                    if (checked && !exists) {
                                      setValue("scope.datasets", [
                                        ...(watch("scope.datasets") || []),
                                        key
                                      ]);
                                    } else if (!checked && exists) {
                                      setValue(
                                        "scope.datasets",
                                        (watch("scope.datasets") || []).filter(
                                          item => item !== key
                                        )
                                      );
                                    }

                                    return;
                                  }}
                                />
                                <span>Billing and Costs</span>
                              </label>
                            )}
                            name="scope.datasets"
                          />
                        </div>
                        <HelperText valid={false}>
                          {errors.scope?.datasets?.message}
                        </HelperText>
                      </div>
                    ) : (
                      <span className="font-semibold flex flex-col justify-start items-start gap-2">
                        {(connection.scope?.datasets || ["--"]).map(dataset => (
                          <span key={dataset}>
                            {{
                              ELECTRICITY_USAGE: "Electricity Usage",
                              BILLING_AND_COSTS: "Billing and Costs"
                            }[dataset] || dataset}
                          </span>
                        ))}
                      </span>
                    )}
                  </div>
                </li>
              </ul>

              <label className="self-center">Data Pull Schedule:</label>
              {isEditing ? (
                <div>
                  <FormField
                    name="dataPullSchedule"
                    control={control}
                    render={({ field }) => (
                      <FormItem>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <SelectTrigger className="w-[180px]">
                            <FormControl>
                              <SelectValue placeholder="Select ..." />
                            </FormControl>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {Object.entries(dataPullScheduleValues).map(
                                ([key, value]) => (
                                  <SelectItem key={key} value={key}>
                                    {value}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <HelperText valid={false}>
                          {errors.dataPullSchedule?.message}
                        </HelperText>
                      </FormItem>
                    )}
                  />
                </div>
              ) : (
                <span className="font-semibold">
                  {dataPullScheduleValues[connection.dataPullSchedule]}
                </span>
              )}

              <label className="leading-[33.75px]">
                Utility Selection URL:
              </label>
              {connection.utilitySelectionUrl ? (
                <div className="flex gap-2 items-center justify-start">
                  <Input value={connection.utilitySelectionUrl} readOnly />
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        connection.utilitySelectionUrl
                      );
                      toast({
                        description: "URL copied to clipboard",
                        duration: 1000
                      });
                    }}
                  >
                    Copy
                  </Button>
                </div>
              ) : (
                <span className="font-semibold leading-[33.75px]">--</span>
              )}

              <label className="leading-[33.75px]">Authorization URL:</label>
              {connection.greenButton?.authorizationRequestUrl ? (
                <div className="flex gap-2 items-center justify-start">
                  <Input
                    value={connection.greenButton.authorizationRequestUrl}
                    readOnly
                  />
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        connection.greenButton.authorizationRequestUrl
                      );
                      toast({
                        description: "URL copied to clipboard",
                        duration: 1000
                      });
                    }}
                  >
                    Copy
                  </Button>
                </div>
              ) : (
                <span className="font-semibold leading-[33.75px]">--</span>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <label>Utility Accounts:</label>
              <ul className="list-disc pl-8">
                <li>12345678901234567890</li>
                <li>12345678901234567890</li>
              </ul>
              <label>Meters:</label>
              <ul className="list-disc pl-8">
                <li>1234567890</li>
                <li>9876543210</li>
              </ul>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
