import PropTypes from "prop-types";
import React from "react";

import { useEnumerateUserProfiles } from "@/_hooks";
import { Spinner } from "@enpowered/ui";
import { InfoPanel } from "@/_components/InfoPanel";

/**
 *
 * @param {object} props
 * @param {string} props.accountId
 * @returns
 */
export const UsersListPanel = ({ accountId }) => {
  const { data: companyAccountUsers = [], isLoading } =
    useEnumerateUserProfiles({ orgProfileId: accountId });

  const users = companyAccountUsers
    .slice(0)
    .sort((a, b) => (a?.family_name || "").localeCompare(b?.family_name));

  return (
    <InfoPanel
      key="Portal Users"
      title={
        <>
          <span>Portal Users</span> {isLoading && <Spinner />}
        </>
      }
    >
      {users?.length > 0 &&
        users.map(user => <UsersListItem user={user} key={user.userId} />)}

      {!users?.length && !isLoading && (
        <div className="mb-2">No users were found</div>
      )}
    </InfoPanel>
  );
};

UsersListPanel.propTypes = {
  accountId: PropTypes.string
};

/**
 *
 * @param {object} props
 * @param {import("@/_services").UserProfile} props.user
 * @returns
 */
export const UsersListItem = ({ user }) => {
  return (
    <div className="user-details flex justify-between mb-2 border-t-2 pt-2 first:border-t-0 first:-mt-2">
      <div className="flex items-center flex-grow justify-between">
        <div className="text-sm pr-2">
          <div className="font-bold">{`${user.given_name} ${user.family_name}`}</div>
          <div className="text-gray-600 inline-block">{user.email}</div>
        </div>
      </div>
    </div>
  );
};

UsersListItem.propTypes = {
  user: PropTypes.object
};
