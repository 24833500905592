import React, { useState } from "react";

import {
  useEnumerateNotificationRecipients,
  useEnumerateOrgProfiles,
  useEnumerateSites,
  useQueryEnergyPrograms,
  useUpdateSite
} from "@/_hooks";
import { Link, useParams } from "react-router-dom";
import { AddSiteConfigFormModal } from "./components/AddSiteConfigForm";
import { DetailSiteConfigFormModal } from "./components/DetailSiteConfigForm";
import { Button, Modal, Spinner } from "@enpowered/ui";
import { Layout } from "@/_components/Layout";
import { SiteInfoPanel } from "@/sites/components/SiteInfoPanel";
import { SiteFormModal } from "@/sites/components/SiteForm";
import { SiteConfigsListPanel } from "@/sites/components/SiteConfigsListPanel";
import { NotificationRecipientsListPanel } from "@/sites/components/NotificationRecipientsListPanel";

/**
 *
 * @returns {JSX.Element}
 */
export const SitesPage = () => {
  const [showEditSiteInfoModal, setShowEditSiteInfoModal] = useState(false);
  const [showAddSiteConfigModal, setShowAddSiteConfigModal] = useState(false);
  const [showDetailSiteConfigModal, setShowDetailSiteConfigModal] =
    useState(false);
  const [showDeleteModalPrompt, setShowDeleteModalPrompt] = useState(false);
  const [selectedEnergyProgramId, setSelectedEnergyProgramId] = useState(null);
  const [selectedSiteConfig, setSelectedSiteConfig] = useState(null);

  const { companyAccountId, siteId } = useParams();

  // Get account data
  const {
    isLoading: isAccountLoading,
    data: { items: [account] } = { items: [] }
  } = useEnumerateOrgProfiles({ orgProfileId: companyAccountId });

  // Get active energy programs
  const { data: energyProgramsList, isLoading: isQueryEnergyProgramsLoading } =
    useQueryEnergyPrograms({ isActive: true, itemsPerPage: 100 });

  // Get recipients
  const {
    data: { items: recipients } = { items: [] },
    isLoading: isEnumerateNotificationRecipientsLoading
  } = useEnumerateNotificationRecipients({
    itemsPerPage: 60,
    siteId: siteId
  });

  const energyProgramsMap = energyProgramsList?.items
    ? Object.fromEntries(
        energyProgramsList.items.map(item => [item.programId, item])
      )
    : {};

  // Get site data
  const { data: sites, isLoading: isEnumerateSitesLoading } = useEnumerateSites(
    { id: siteId }
  );

  const site = sites?.[0] || null;
  const isLoading =
    isAccountLoading || isEnumerateSitesLoading || isQueryEnergyProgramsLoading;

  const {
    mutate: updateSite,
    isLoading: isUpdateSiteLoading,
    error: updateSiteError
  } = useUpdateSite(() => {
    setShowEditSiteInfoModal(false);
    setShowDetailSiteConfigModal(false);
    setShowDeleteModalPrompt(false);
    setSelectedEnergyProgramId(null);
    setSelectedSiteConfig(null);
  });

  const usedEnergyPrograms =
    site?.siteConfig?.map(({ programId }) => programId) || [];
  const usedSystem =
    energyProgramsMap[site?.siteConfig?.[0]?.programId]?.system || null;

  const availableEnergyPrograms =
    energyProgramsList?.items
      .filter(energyProgram =>
        usedSystem
          ? energyProgram.system === usedSystem &&
            !usedEnergyPrograms.includes(energyProgram.programId)
          : !usedEnergyPrograms.includes(energyProgram.programId)
      )
      .map(energyProgram => ({
        id: energyProgram.programId,
        programType: energyProgram.programType,
        system: energyProgram.system,
        label: `${energyProgram?.system} - ${energyProgram?.nickname} Program`
      })) || [];

  /**
   *
   * @param {import("@/_services").SiteConfig} siteConfig
   */
  const removeSiteConfig = siteConfig => {
    const params = {
      ...site,
      siteConfig: site.siteConfig.filter(
        config => config.programId !== siteConfig.programId
      )
    };

    updateSite(params);
  };

  return (
    <Layout pageTitle="Site">
      <div
        className="h-full grid overflow-y-hide p-4"
        style={{ gridTemplateRows: "auto 1fr" }}
      >
        <Modal
          isOpen={showDeleteModalPrompt}
          title="Delete Site Program"
          onClose={() => setShowDeleteModalPrompt(false)}
          size="md"
        >
          <>
            <p>Are you sure you wish to delete this program?</p>
            {updateSiteError && (
              <label className="block text-sm py-2 text-center text-red-800">
                {
                  // @ts-ignore
                  updateSiteError?.errors?.[0]?.detail
                }
              </label>
            )}
            <div className="mt-4 flex justify-end">
              <Button
                className="bg-white border border-en-gray-500 mr-4"
                theme="none"
                size="narrow"
                onClick={() => setShowDeleteModalPrompt(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={isUpdateSiteLoading}
                theme="dark"
                size="narrow"
                onClick={() => removeSiteConfig(selectedSiteConfig)}
              >
                {isUpdateSiteLoading ? (
                  <span>
                    <i className="fa mx-4 fa-circle-notch fa-pulse text-white" />
                  </span>
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          </>
        </Modal>
        <div className="mb-4 flex justify-between items-center">
          <Link
            to={`/organizations/${companyAccountId}`}
            className="flex justify-start items-center font-bold text-lg"
          >
            <i className="fa fa-arrow-left mr-2"></i>
            back to {account?.name}
          </Link>

          <div>{isLoading && <Spinner />}</div>
        </div>

        {!isLoading && (
          <div
            className="h-full grid w-full bg-transparent gap-4"
            style={{ gridTemplateColumns: "300px 1fr 1fr" }}
          >
            <div>
              {!!account && !!site && (
                <>
                  <SiteInfoPanel
                    accountName={account.name}
                    accountSite={site}
                    onClickEditButton={() => setShowEditSiteInfoModal(true)}
                  />

                  <SiteFormModal
                    isOpen={showEditSiteInfoModal}
                    site={site}
                    isLoading={isUpdateSiteLoading}
                    onCancel={() => setShowEditSiteInfoModal(false)}
                    onSubmit={updateSite}
                    account={account}
                    error={updateSiteError}
                  />
                </>
              )}
            </div>
            <div>
              <SiteConfigsListPanel
                energyPrograms={energyProgramsList.items}
                site={site}
                onAdd={() => {
                  setShowAddSiteConfigModal(true);
                }}
                onEdit={siteConfig => {
                  setSelectedEnergyProgramId(siteConfig.programId);
                  setSelectedSiteConfig(siteConfig);
                  setShowDetailSiteConfigModal(true);
                }}
                onRemove={siteConfig => {
                  setShowDeleteModalPrompt(true);
                  setSelectedSiteConfig(siteConfig);
                }}
                onReorder={siteConfigs => {
                  updateSite({
                    ...site,
                    siteConfig: siteConfigs
                  });
                }}
                addProgramsEnabled={!!availableEnergyPrograms.length}
                isLoading={isUpdateSiteLoading}
              />

              <AddSiteConfigFormModal
                isOpen={showAddSiteConfigModal}
                onSubmit={selectedOption => {
                  setSelectedEnergyProgramId(selectedOption);
                  setSelectedSiteConfig(null);
                  setShowAddSiteConfigModal(false);
                  setShowDetailSiteConfigModal(true);
                }}
                onCancel={() => {
                  setShowAddSiteConfigModal(false);
                }}
                options={availableEnergyPrograms}
              />

              <DetailSiteConfigFormModal
                isOpen={showDetailSiteConfigModal}
                onSubmit={siteConfig => {
                  if (!site.siteConfig) site.siteConfig = [];

                  if (!selectedSiteConfig) {
                    site.siteConfig.push(siteConfig);
                  } else {
                    site.siteConfig = site.siteConfig.map(existing =>
                      existing.programId === siteConfig.programId
                        ? siteConfig
                        : existing
                    );
                  }

                  updateSite(site);
                  setSelectedSiteConfig(siteConfig);
                }}
                onCancel={() => {
                  setShowDetailSiteConfigModal(false);
                }}
                energyProgram={energyProgramsMap[selectedEnergyProgramId]}
                siteConfig={selectedSiteConfig}
                isLoading={isUpdateSiteLoading}
                error={updateSiteError}
              />
            </div>
            <div>
              <NotificationRecipientsListPanel
                recipients={recipients}
                isLoading={isEnumerateNotificationRecipientsLoading}
              />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
