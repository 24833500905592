import { authedFetch } from "@/_utils/authedFetch";
import { callJsonRPC } from "@/_utils/call-json-rpc";

const prefix = "com.enpowered.iam";
const METHOD = {
  createOrgProfile: `${prefix}:createOrgProfile`,
  updateOrgProfile: `${prefix}:updateOrgProfile`,
  enumerateOrgProfiles: `${prefix}:enumerateOrgProfiles`,
  enumerateUserProfiles: `${prefix}:enumerateUserProfiles`
};

const apiRoot = `${process.env.REACT_APP_API_ROOT}/iam/jsonrpc`;

/**
 * Create Org Profile
 * @param {OrgProfile} data
 * @returns {Promise<{orgProfileId: string}>}
 */
export const createOrgProfile = data =>
  callJsonRPC(apiRoot, METHOD.createOrgProfile, data);

/**
 * Enumerate User Profiles
 * @param {OrgProfile} data
 * @returns {Promise<{orgProfileId: string}>}
 */
export const updateOrgProfile = data =>
  callJsonRPC(apiRoot, METHOD.updateOrgProfile, data);

/**
 * Enumerate Org Profiles
 * @param {object} data
 * @param {string} [data.orgProfileId]
 * @param {number} [data.itemsPerPage]
 * @param {string} [data.cursor]
 * @returns {Promise<{items: OrgProfile[], cursor?:string}>}
 */
export const enumerateOrgProfiles = data =>
  callJsonRPC(apiRoot, METHOD.enumerateOrgProfiles, data);

/**
 * Enumerate User Profiles
 * @param {object} data
 * @param {string} [data.userId]
 * @param {string} [data.orgProfileId]
 * @param {number} [data.itemsPerPage]
 * @param {string} [data.permission]
 * @param {string} [data.cursor]
 * @returns {Promise<{items: UserProfile[], cursor?:string}>}
 */
export const enumerateUserProfiles = (data, fetch = authedFetch) =>
  callJsonRPC(apiRoot, METHOD.enumerateUserProfiles, data, fetch);

/**
 * @typedef {object} OrgProfile
 * @property {string} [orgProfileId]
 * @property {string} [name]
 * @property {Address} address
 *
 * @typedef {object} Address
 * @property {string} streetAddress
 * @property {string} city
 * @property {string} state
 * @property {string} country
 * @property {string} postalCode
 *
 * @typedef {object} UserProfile
 * @property {string} userId
 * @property {string} email
 * @property {string} given_name
 * @property {string} family_name
 * @property {string} phone_number
 * @property {string[]} authorizationScope
 */
